import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import Holiday from './Holiday';
import Sidebar from '../../Sidebar';
import { UndoButton } from '../../Buttons';
import holidayStore from '../../../stores/HolidayStore';
import {
  updateHoliday,
  getHoliday,
  getHolidays,
  clearHoliday,
} from '../../../actions/HolidayActions';
import withState from '../../../utils/with-state';
import styles from '../../configuration/EditForm.module.css';

function EditHoliday({ holiday, match }) {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(null);

  const clearError = () => setError(null);
  useEffect(() => {
    const id = match?.params?.id;
    if (id) getHoliday(id);
  }, [match]);

  function onSaveHoliday({
    name,
    start,
    end,
    relations,
    setAlarm,
    isRecurring,
  }) {
    if (holiday && holiday.id) {
      updateHoliday({
        id: holiday.id,
        name,
        start,
        end,
        relations,
        setAlarm,
        isRecurring,
      })
        .then(() => {
          clearHoliday();
          getHolidays();
          setRedirect(true);
        })
        .catch((e) => setError(e));
    }
  }

  if (redirect) return <Redirect to="/admin/holidays" />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title={t('Justera din helgdag')}
          text={t('Uppdatera din valda helgdag här')}
          Icon={<EventBusyIcon fontSize="large" />}
        />
        {holiday && (
          <div className={styles.content}>
            <div className={styles.header}>
              <h2>{t('Helgdagshanterare')}</h2>
              <UndoButton href="/admin/holidays">
                {t('Ångra & stäng')}
              </UndoButton>
            </div>
            <div className={styles.form}>
              {error && (
                <p className={styles.error}>
                  {error?.response?.data?.error?.details}
                </p>
              )}
              <Holiday
                holiday={holiday}
                onSubmit={onSaveHoliday}
                clearError={clearError}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withState(EditHoliday, holidayStore, (state) => ({
  holiday: state.holiday,
}));
