import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { svSE, enUS } from '@material-ui/core/locale';
import SearchBar from './SearchBar';
import styles from './Table.module.css';

const noop = () => {};

function Header({ query, setQuery, list, placeholder, hideSearch, setPage }) {
  return (
    <div className={styles.search}>
      {!hideSearch && (
        <SearchBar
          value={query}
          onInput={(e) => {
            setPage(0);
            setQuery(e.target.value);
          }}
          placeholder={placeholder}
          list={list}
        />
      )}
    </div>
  );
}

function MyTableHead({
  head,
  headerTooltips = {},
  onClickHeader,
  activeHeader,
}) {
  return (
    <TableHead className={styles.th}>
      <TableRow className={classNames(styles.row, styles.header)}>
        {(head || []).map((h) =>
          headerTooltips?.[h] ? (
            <Tooltip
              title={<span style={{ fontSize: 16 }}>{headerTooltips[h]}</span>}
            >
              <TableCell
                onClick={() => onClickHeader(h)}
                key={h}
                className={classNames(styles.header, {
                  [styles.activeHeader]: activeHeader === h,
                  [styles.clickable]: onClickHeader !== noop,
                })}
              >
                {h}
                <HelpIcon style={{ fontSize: 16 }} />
              </TableCell>
            </Tooltip>
          ) : (
            <TableCell
              onClick={() => onClickHeader(h)}
              key={h}
              className={classNames(styles.header, {
                [styles.activeHeader]: activeHeader === h,
                [styles.clickable]: onClickHeader !== noop,
              })}
            >
              {h}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

function Row({ entry, className }) {
  return (
    <TableRow
      className={classNames(styles.row, className)}
      key={`row-${entry?.[0]}`}
    >
      {Object.values(entry).map((x, idx) => (
        <TableCell key={`cell-${idx}-row-${x}`}>{x}</TableCell>
      ))}
    </TableRow>
  );
}

function MyTable({
  data,
  hideSearch,
  noPagination = false,
  searchPlaceholder,
  rowClass,
  headerTooltips = {},
  total,
  onFetchMore = noop,
  activeHeader = null,
  onClickHeader = noop,
}) {
  const { i18n } = useTranslation();
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const head = Object.keys(data?.[0] || {});
  const list = (data || []).map((d) => Object.values(d));

  const valueEqFunc = (e, q) =>
    (e || '').toString().toLowerCase().includes(q.toLowerCase());

  const terms = query.split(' ');
  const filteredList = list.filter((entry) =>
    terms.every((t) =>
      Object.values(entry).some((e) => {
        if (e?.props?.value) return valueEqFunc(e.props.value, t); // Object e.g. PhoneInput
        return valueEqFunc(e, t);
      })
    )
  );

  const theme = createTheme(
    {
      typography: {
        fontFamily: 'MaisonNeue',
      },
    },
    i18n.language === 'sv' ? svSE : enUS
  );

  function handlePageChange(e, newPage) {
    if (total > data.length && newPage * rowsPerPage > data.length) {
      onFetchMore();
    }
    setPage(newPage);
  }
  function handleRowsPerPageChange(e) {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  }

  /*
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredList.length - page * rowsPerPage);
    */

  return (
    <div className={styles.container}>
      <Header
        query={query}
        setQuery={setQuery}
        placeholder={searchPlaceholder}
        hideSearch={hideSearch}
        setPage={setPage}
      />
      <ThemeProvider theme={theme}>
        <TableContainer>
          <Table size="small" className={styles.list}>
            <MyTableHead
              onClickHeader={onClickHeader}
              head={head}
              headerTooltips={headerTooltips}
              activeHeader={activeHeader}
            />
            <TableBody>
              {filteredList
                .slice(
                  noPagination ? 0 : page * rowsPerPage,
                  noPagination
                    ? filteredList.length
                    : page * rowsPerPage + rowsPerPage
                )
                .map((ent, idx) => (
                  <Row key={idx} entry={ent} className={rowClass} />
                ))}
              {/* emptyRows > 0 && (
                <TableRow style={{ height: (rowHeight || 61) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              ) */}
            </TableBody>
          </Table>
        </TableContainer>
        {!noPagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={total || data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </ThemeProvider>
    </div>
  );
}
export default MyTable;
