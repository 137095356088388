import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { SaveButton } from '../Buttons';
import Loading from '../Loading';
import PhoneField from '../PhoneField';
import TextInput from '../TextInput';
import siteStore from '../../stores/SiteStore';
import userStore from '../../stores/UserStore';
import { fetchSites } from '../../actions/SiteActions';
import { getUser, editUser, clearUser } from '../../actions/UserActions';
import { withStates } from '../../utils/with-state';
import styles from './EditUser.module.css';

function EditUser({ user, sites }) {
  const { t } = useTranslation();

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [phoneNbr, setPhoneNbr] = useState('');
  const [locale, setLocale] = useState('sv');
  const [err, setErr] = useState({
    fname: {
      err: false,
      text: t('Måste vara mellan 1 och 255 tecken'),
    },
    lname: {
      err: false,
      text: t('Måste vara mellan 1 och 255 tecken'),
    },
  });

  useEffect(() => {
    fetchSites();
    getUser(0);
    return () => {
      clearUser();
    };
  }, []);
  useEffect(() => {
    if (user && sites) {
      setFname(user.firstName);
      setLname(user.lastName);
      setPhoneNbr(user.phoneNbr);
      setLocale(user.locale);
    }
  }, [user, sites]);

  function checkError() {
    for (let e of Object.keys(err)) {
      let eVal;
      switch (e) {
        case 'fname':
          eVal = fname.length > 255 || fname.length < 1;
          break;
        case 'lname':
          eVal = lname.length > 255 || lname.length < 1;
          break;
        default:
          break;
      }
      if (eVal !== null) {
        let newErr = { ...err };
        newErr[e].err = eVal;
        setErr(newErr);
      }
    }
    return Object.values(err).some((x) => x.err);
  }
  function handleChange(id, val) {
    switch (id) {
      case 'fname':
        setFname(val);
        break;
      case 'lname':
        setLname(val);
        break;
      case 'phoneNbr':
        setPhoneNbr(val);
        break;
      case 'locale':
        setLocale(val);
        break;
      default:
        break;
    }
    setErr({ ...err, [id]: { ...err[id], err: false } });
  }
  function onSave(e) {
    e.preventDefault();
    if (!user || checkError()) return;

    editUser({
      id: user.id,
      firstName: fname,
      lastName: lname,
      phoneNbr,
      locale,
      sites: sites.map((site) => site.id),
    }).then(() => getUser(0));
  }

  if (!user) return <Loading center big />;

  return (
    <form onSubmit={onSave} className={styles.form}>
      <div className={styles.row}>
        <TextInput
          className={styles.entry}
          label={t('Förnamn')}
          value={fname}
          onChange={(e) => handleChange('fname', e.target.value)}
          error={err.fname.err}
          helperText={err.fname.err ? err.fname.text : null}
        />
        <TextInput
          className={styles.entry}
          label={t('Efternamn')}
          value={lname}
          onChange={(e) => handleChange('lname', e.target.value)}
          error={err.lname.err}
          helperText={err.lname.err ? err.lname.text : null}
        />
      </div>
      <TextInput
        label={t('Email')}
        value={user?.email}
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        disabled
      />
      <div className={styles.row}>
        <TextInput
          className={styles.entry}
          label={t('Roll')}
          value={user?.role}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          disabled
        />
        <PhoneField
          label={t('Telefon')}
          value={phoneNbr}
          onChange={(e) => handleChange('phoneNbr', e)}
        />
      </div>
      <div className={styles.row}>
        <FormControl
          fullWidth
          variant="outlined"
          margin="dense"
          className={styles.siteRetainDaysSelect}
        >
          <InputLabel htmlFor="user-locale">{t('Språk')}</InputLabel>
          <Select
            label={t('Välj språk')}
            labelId="user-locale"
            value={locale}
            onChange={(e) => setLocale(e.target.value)}
            MenuProps={{ variant: 'menu' }}
          >
            {[
              { value: 'sv', label: 'Svenska' },
              { value: 'en', label: 'English' },
            ].map((x, idx) => (
              <MenuItem key={idx} value={x.value}>
                {x.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={styles.footer}>
        <SaveButton type="submit">{t('Spara ändringar')}</SaveButton>
      </div>
    </form>
  );
}

const siteMapper = (state) => ({ sites: state.sites });
const userMapper = (state) => ({ user: state.userInfo });

export default withStates(
  EditUser,
  [siteStore, userStore],
  [siteMapper, userMapper]
);
