import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { GROUP_MAP, VERB_MAP } from '../../../constants';
import Loading from '../../Loading';
import { SaveButton } from '../../Buttons';
import TextInput from '../../TextInput';
import hStyles from './PermissionGroup.module.css';
import fStyles from '../../configuration/Form.module.css';

const styles = { ...fStyles, ...hStyles };

function PermissionGroup({
  permissionGroup,
  permissions,
  onSubmit,
  clearError,
}) {
  const { t } = useTranslation();
  const [name, setName] = useState(permissionGroup?.name || '');
  const [isAdminExclusive, setIsAdminExclusive] = useState(
    permissionGroup?.isAdminExclusive || false
  );
  const [currentPermissions, setCurrentPermissions] = useState(null);

  useEffect(() => {
    if (permissions) {
      setCurrentPermissions(
        permissions.reduce((acc, cur) => {
          acc[cur] = permissionGroup?.permissions?.[cur] || false;
          return acc;
        }, {})
      );
    }
  }, [permissions, permissionGroup]);

  const groupedPermissions = useMemo(() => {
    if (!permissions) return null;

    return permissions.reduce((acc, cur) => {
      const words = cur
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .split(' ')
        .map((x) => x.toLowerCase());
      const verb = words[words.length - 1];
      const key = words.slice(0, words.length - 1).join(' ');

      acc[key] = acc[key] || {};
      acc[key][verb] = cur;
      return acc;
    }, {});
  }, [permissions]);

  const [err, setErr] = useState({
    name: {
      err: false,
      text: t('Måste vara mellan 1 och 255 tecken'),
    },
  });

  function checkError() {
    for (let e of Object.keys(err)) {
      let eVal;
      switch (e) {
        case 'name':
          eVal = name.length > 255 || name.length < 1;
          break;
        default:
          break;
      }
      if (eVal !== null) {
        let newErr = { ...err };
        newErr[e].err = eVal;
        setErr(newErr);
      }
    }
    return Object.values(err).some((x) => x.err);
  }
  function handleChange(id, val) {
    switch (id) {
      case 'name':
        setName(val);
        setErr({ ...err, name: { ...err.name, err: false } });
        break;
      case 'isAdminExclusive':
        setIsAdminExclusive(val);
        break;
      default:
        break;
    }
    clearError();
  }
  function onSave(e) {
    e.preventDefault();
    if (!checkError()) {
      onSubmit({
        name,
        permissions: currentPermissions,
        isAdminExclusive,
      });
    }
  }

  if (!groupedPermissions || !currentPermissions) return <Loading />;

  return (
    <div className={styles.container}>
      <form className={styles.flexColumn} onSubmit={onSave}>
        <div className={styles.section}>
          <TextInput
            label={t('Namn')}
            value={name}
            onChange={(e) => handleChange('name', e.target.value)}
            error={err.name.err}
            helperText={err.name.err ? err.name.text : null}
          />
        </div>

        <div className={styles.repeatCheck}>
          <p>{t('Tillåt endast admin tilldela denna behörighetsgrupp')}</p>
          <Checkbox
            checked={isAdminExclusive}
            onChange={(e) => handleChange('isAdminExclusive', e.target.checked)}
          />
        </div>

        <Divider light />

        <List>
          <ListSubheader>{t('Behörigheter för gruppen')}</ListSubheader>
          {Object.keys(groupedPermissions).map((key) => (
            <React.Fragment key={key}>
              <ListItem>
                <ListItemText
                  primary={
                    <span className={styles.groupHeader}>
                      {GROUP_MAP[key] || key}
                    </span>
                  }
                  secondary={
                    <FormGroup row>
                      {Object.keys(groupedPermissions[key]).map((verb) => (
                        <React.Fragment key={verb}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  currentPermissions[
                                    groupedPermissions[key][verb]
                                  ]
                                }
                                onChange={(e) => {
                                  setCurrentPermissions({
                                    ...currentPermissions,
                                    [groupedPermissions[key][verb]]:
                                      e.target.checked,
                                  });
                                }}
                              />
                            }
                            label={VERB_MAP[verb] || verb}
                          />
                        </React.Fragment>
                      ))}
                    </FormGroup>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>

        <div>
          <div className={styles.footer}>
            <SaveButton type="submit">{t('Spara')}</SaveButton>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PermissionGroup;
