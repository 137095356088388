import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import {
  Delete,
  Pause as PauseIcon,
  PlayArrow as PlayIcon,
  Warning as WarnIcon,
  Lock as CodeIcon,
  Person as UserIcon,
} from '@material-ui/icons';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { SaveButton } from '../Buttons';
import Loading from '../Loading';
import Sidebar from '../Sidebar';
import TextInput from '../TextInput';
import ConfirmDialog from '../configuration/ConfirmDialog';
import {
  getSiteInfo,
  editSiteInfo,
  clearSiteInfo,
  deleteSite as deleteSiteAction,
  fetchSites,
} from '../../actions/SiteActions';
import {
  getTimeoutStatus,
  activateTimeout,
  deactivateTimeout,
  forceTimeoutDeactivation,
} from '../../actions/TimeoutActions';
import { getUser } from '../../actions/UserActions';
import siteStore from '../../stores/SiteStore';
import timeoutStore from '../../stores/TimeoutStore';
import userStore from '../../stores/UserStore';
import { withStates } from '../../utils/with-state';
import styles from './Site.module.css';

function formatLocation({ latitude, longitude }) {
  return `${latitude},${longitude}`;
}

function validateLocation(location) {
  try {
    const parts = location.split(',');

    if (parts.length !== 2) {
      return false;
    }
    const [latitude, longitude] = parts.map(parseFloat);
    return (
      Number.isFinite(latitude) &&
      Math.abs(latitude) <= 90 &&
      Number.isFinite(longitude) &&
      Math.abs(longitude) <= 180
    );
  } catch (err) {
    return false;
  }
}

function parseLocation(location) {
  try {
    if (!validateLocation(location)) {
      return null;
    }
    const [latitude, longitude] = location.split(',').map(parseFloat);
    return { latitude, longitude };
  } catch (err) {
    return null;
  }
}

const ToggleTimeoutButton = ({ isActive, activatorType, toggleTimeout }) => (
  <IconButton
    onClick={toggleTimeout}
    className={classNames(styles.timeoutButton, {
      [styles.active]: isActive,
      [styles.inactive]: !isActive,
    })}
  >
    {isActive && activatorType === 'SIGNED_IN' ? <PauseIcon /> : <PlayIcon />}
  </IconButton>
);

const tsFormat = 'Y-MM-DD HH:mm:ss';
const TimeoutBlock = ({ siteId, timeout, activatedByMe }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.timeoutBlock}>
      <div
        className={classNames(styles.statusText, {
          [styles.active]: timeout?.isActive,
          [styles.inactive]: !timeout?.isActive,
        })}
      >
        <span
          className={classNames(styles.status, {
            [styles.active]: timeout?.isActive,
            [styles.inactive]: !timeout?.isActive,
          })}
        ></span>
        <p>
          {timeout?.isActive
            ? t('Anläggning olarmad (!)')
            : t('Anläggning larmad')}
          {timeout?.isActive && !activatedByMe && t(' (av någon annan)')}
        </p>
      </div>
      {timeout?.isActive && (
        <Button
          className={styles.forceDeactButton}
          onClick={() => forceTimeoutDeactivation(siteId)}
          startIcon={<WarnIcon />}
        >
          t("Forcera återställning")
        </Button>
      )}
      {!!timeout?.endsAt && (
        <p className={styles.endsAtText}>
          {moment(timeout.endsAt).diff(moment(), 'years') < 20
            ? t('Larmet åter {{endsAt}}', {
                endsAt: moment(timeout.endsAt).format(tsFormat),
              })
            : t('Larmet är pausat tills vidare')}
        </p>
      )}
      {!!timeout?.activatedBy && !!timeout?.startedAt && (
        <div className={styles.activatedByText}>
          <p>
            {t('Anläggningen avlarmades {{startedAt}}', {
              startedAt: moment(timeout.startedAt).format(tsFormat),
            })}
          </p>
          <p className={styles.activatedById}>
            {timeout.activatedBy.includes('kod') ? (
              <CodeIcon className={styles.activatedByIcon} />
            ) : (
              <UserIcon className={styles.activatedByIcon} />
            )}
            {
              timeout.activatedBy.replace(
                /\(([^)]+)\)\s/,
                ''
              ) /* removes the '(kod) ' and '(användare) ' prefixes */
            }
          </p>
        </div>
      )}
    </div>
  );
};

let timeoutInterval;

function Site({ siteInfo, timeoutStatus, userInfo, match }) {
  const { t } = useTranslation();
  const siteId = match?.params?.id;
  const userId = userInfo?.id;
  const [redirect, setRedirect] = useState(false);
  const [info, setInfo] = useState('');
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [retainDays, setRetainDays] = useState(30);
  const [toDelete, setToDelete] = useState(false);
  const [location, setLocation] = useState('');

  useEffect(() => {
    getUser(0);
    return () => {
      clearSiteInfo();
    };
  }, []);
  useEffect(() => {
    clearSiteInfo();
    if (siteId) {
      getSiteInfo(siteId);
      getTimeoutStatus(siteId);
      clearInterval(timeoutInterval);
      timeoutInterval = setInterval(() => {
        getTimeoutStatus(siteId);
      }, 3000);
    }
    return () => {
      clearInterval(timeoutInterval);
    };
  }, [siteId]);
  useEffect(() => {
    if (siteInfo) {
      setInfo(siteInfo.info);
      setAddress(siteInfo?.address || '');
      setName(siteInfo?.name || '');
      setRetainDays(siteInfo?.retainDays);

      if (siteInfo?.location) {
        setLocation(formatLocation(siteInfo.location));
      } else {
        setLocation('');
      }
    }
  }, [siteInfo]);

  function onSave() {
    editSiteInfo(
      siteInfo.id,
      info,
      address,
      retainDays,
      parseLocation(location),
      name
    ).then(() => {
      clearSiteInfo();
      fetchSites(false);
      setRedirect(true);
    });
  }

  function deleteSite() {
    deleteSiteAction(siteId).then(() => {
      fetchSites(false);
      setRedirect(true);
    });
  }

  async function toggleTimeout() {
    if (!timeoutStatus) return;
    if (timeoutStatus?.isActive && timeoutStatus?.activatorType === 'SIGNED_IN')
      await deactivateTimeout(siteId);
    else await activateTimeout(siteId);
  }
  if (redirect) return <Redirect to="/admin/sites" />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title={t('Översikt')}
          text={t(
            'Samlad information och inställningar för den valda anläggningen'
          )}
          backRoute="/admin/sites"
        />
        <div className={styles.form}>
          {!siteInfo ? (
            <Loading big center />
          ) : (
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.headerTitle}>
                  <ToggleTimeoutButton
                    isActive={timeoutStatus?.isActive}
                    toggleTimeout={toggleTimeout}
                    activatorType={timeoutStatus?.activatorType}
                  />
                  <h2 className={styles.name}>
                    {siteInfo?.name}
                    <span>(ID: {siteId})</span>
                  </h2>
                </div>
                <div className={styles.headerButtons}>
                  <Button onClick={() => setRedirect(true)}>
                    {t('Ångra & Stäng')}
                  </Button>
                  <SaveButton onClick={onSave}>{t('Spara & stäng')}</SaveButton>
                </div>
              </div>
              <div className={styles.body}>
                <TimeoutBlock
                  siteId={siteId}
                  timeout={timeoutStatus}
                  activatedByMe={
                    timeoutStatus?.activatorType === 'SIGNED_IN' &&
                    timeoutStatus?.activatorId === userId
                  }
                />
                <div className={styles.siteInfo}>
                  <TextInput
                    label={t('Namn')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    helperText={t('Namn på anläggningen')}
                  />

                  <TextInput
                    label={t('Address')}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    helperText={t(
                      'Adressen kommer användas av väktaren, så se till att den är korrekt'
                    )}
                  />
                  <TextInput
                    label={t('Position')}
                    value={location}
                    error={!validateLocation(location)}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <TextInput
                    label={t('Information')}
                    multiline
                    rows="3"
                    rowsMax="7"
                    value={info}
                    onChange={(e) => setInfo(e.target.value)}
                    helperText={t('Övrig information')}
                  />
                  <FormControl variant="outlined" margin="dense">
                    <InputLabel htmlFor="retain-days">
                      {t('Dagar att spara video')}
                    </InputLabel>
                    <Select
                      label={t('Dagar att spara video')}
                      labelId="retain-days"
                      type="number"
                      value={retainDays}
                      onChange={(e) => setRetainDays(e.target.value)}
                      renderValue={(v) => `${v} dagar`}
                    >
                      {[3, 14, 30].map((x, idx) => (
                        <MenuItem key={idx} value={x}>
                          {t('{{days}} dagar', { days: x })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <div className={styles.confirmDel}>
                    <div>
                      <SaveButton
                        admin
                        onClick={() => setToDelete(true)}
                        startIcon={<Delete />}
                        className={styles.delButton}
                      >
                        {t('Radera anläggning')}
                      </SaveButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ConfirmDialog
        open={toDelete}
        title={t('Radera anläggning')}
        text={
          <>
            <p>
              {t('Är du säker på att du vill radera {{name}}?', {
                name: siteInfo?.name,
              })}
            </p>
            <p>{t('Detta kommer ta bort ALL data relaterad till den!')}</p>
          </>
        }
        onDeny={() => setToDelete(false)}
        onConfirm={deleteSite}
      />
    </div>
  );
}

const siteMapper = (state) => ({ siteInfo: state.siteInfo });
const timeoutMapper = (state) => ({ timeoutStatus: state.timeoutStatus });
const userMapper = (state) => ({ userInfo: state.userInfo });

export default withStates(
  Site,
  [siteStore, timeoutStore, userStore],
  [siteMapper, timeoutMapper, userMapper]
);
