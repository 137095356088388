import userStore from '../stores/UserStore';
import tfaStore from '../stores/TfaStore';
import { clearAllStores } from './ActionUtils';
import { patch, post, del, put, get } from '../utils/api';
import { createSnack } from './SnackActions';

export function login(email, password, otpToken = '') {
  return post('/auth', { email, password, otpToken }).then((res) => {
    tfaStore.usesTfa = res.data.usesTfa;
    if (res.data?.token) {
      userStore.role = res.data?.role;
      userStore.setToken(res.data.token);
      if (res.data.usesTfa) {
        tfaStore.verified = false;
        tfaStore.usesTfa = true;
      }
    }
    return res.data;
  });
}

export function createUser(
  email,
  firstName,
  lastName,
  role,
  phoneNbr,
  sites,
  permissionGroupId,
  locale = 'en'
) {
  return put('/auth', {
    email,
    firstName,
    lastName,
    role,
    phoneNbr,
    sites,
    permissionGroupId,
    locale,
  })
    .then(() => createSnack('Användare skapad'))
    .catch(() => createSnack('Kunde inte skapa användare'));
}

export function getUser(id) {
  const roles = {
    customer: 'Kund',
    admin: 'Admin',
  };
  return get(`/users/${id}`)
    .then((res) => {
      let user = {
        ...res.data.user,
        role: res.data.user.isEmployee ? 'Anställd' : roles[res.data.user.role],
      };
      userStore.userInfo = user;
    })
    .catch(() => createSnack('Kunde inte hämta användarinfo'));
}

export function editUser({
  id,
  firstName,
  lastName,
  role,
  phoneNbr,
  sites,
  permissionGroupId,
  ignoreSites,
  locale,
}) {
  return patch('/users', {
    id,
    firstName,
    lastName,
    phoneNbr,
    role,
    sites,
    permissionGroupId,
    ignoreSites,
    locale,
  })
    .then(() => createSnack(`Användare uppdaterad`))
    .catch(() => createSnack('Kunde inte uppdatera användare'));
}

export function renewToken() {
  if (userStore.token) {
    return get('/renew-token').then((res) => {
      userStore.setToken(res.data.token);
      userStore.role = res.data?.role;
    });
  }
}

export function logout() {
  return post('/logout')
    .then(() => {
      clearAllStores();
    })
    .catch(() => createSnack('Kunde inte logga ut'));
}

export function changePassword({ oldPass, newPass }) {
  return post('/new-password', { oldPass, newPass }).then(() => {
    logout();
  });
}

export function deleteUserRelation({ siteId, userId }) {
  return del(`/sites/${siteId}/users/${userId}`)
    .then(() => createSnack('Användare borttagen från anläggning'))
    .catch(() => createSnack('Kunde inte ta bort användare från anläggning'));
}

export function requestNewPass({ email }) {
  return post('/forgot-password', { email })
    .then(() =>
      createSnack(
        'En länk för att byta lösenord har skickats till den angivna adressen.'
      )
    )
    .catch(() => createSnack('Kunde inte skapa en länk för att byta lösenord'));
}

export function setNewPassword({ passUrl, newPass }) {
  return put(`/reset-password/${passUrl}`, { newPass })
    .then(() => {
      createSnack('Lösenordet har ändrats');
    })
    .catch(() => createSnack('Ett fel uppstod vid lösenordsändringen'));
}

export function getUserInfo({ id }) {
  const roles = {
    customer: 'Kund',
    admin: 'Admin',
    operator: 'Operatör',
  };
  userStore.userInfo = null;
  return get(`/users/${id}`).then((res) => {
    if (res.data.user) {
      let { user } = res.data;
      user.role = user.isEmployee ? 'Anställd' : roles[user.role];
      userStore.userInfo = user;
    }
  });
}

export function deleteUser(id, { siteId } = {}) {
  if (!id) return Promise.reject();
  return del(`/users/${id}`, { siteId })
    .then(() => createSnack('Användare borttagen'))
    .catch(() => createSnack('Kunde inte ta bort användare'));
}

export function clearUser() {
  userStore.userInfo = null;
}

export function getProfile() {
  return get('/users/0')
    .then((res) => res.data.user)
    .catch((err) => {
      createSnack('Kunde inte hämta användarinfo');
      throw err;
    });
}
