import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, TextField, Divider } from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
  Search,
  Business,
  ArrowDropDown,
} from '@material-ui/icons';
import Loading from './Loading';
import { getSiteInfo, fetchSites } from '../actions/SiteActions';
import { getTimeoutStatus } from '../actions/TimeoutActions';
import siteStore from '../stores/SiteStore';
import timeoutStore from '../stores/TimeoutStore';
import withState from '../utils/with-state';
import styles from './SiteSelector.module.css';

const NAME_LENGTH = 15;

const Searchbar = React.memo(({ val, setVal }) => {
  const { t } = useTranslation();

  return (
    <TextField
      className={styles.searchbar}
      value={val}
      onKeyDown={(e) => e.stopPropagation()}
      onChange={(e) => setVal(e.target.value)}
      InputProps={{ startAdornment: <Search /> }}
      autoFocus
      autoComplete="off"
      placeholder={t('Sök Anläggning')}
    />
  );
});

const timeoutMapper = (state) => ({
  timeoutStatus: state.timeoutStatus,
});
const Header = withState(
  ({ selectedSite, val, setVal, timeoutStatus }) => (
    <div>
      <Searchbar val={val} setVal={setVal} />
      <Divider />
      {selectedSite && (
        <>
          <div className={styles.currentSite}>
            {timeoutStatus?.isActive ? (
              <VisibilityOff className={styles.grayEye} />
            ) : (
              <Visibility className={styles.redEye} />
            )}
            {selectedSite.name}
          </div>
          <Divider />
        </>
      )}
    </div>
  ),
  timeoutStore,
  timeoutMapper
);
Header.displayName = 'Header';

const IdToName = withState(
  ({ id, sites, timeoutStatus }) => (
    <MenuItem value={id} className={styles.entry}>
      {sites.find((site) => id === site.id)?.name ? (
        <>
          {timeoutStatus?.isActive ? (
            <VisibilityOff className={styles.grayEye} />
          ) : (
            <Visibility className={styles.redEye} />
          )}
          <p className={styles.selectedSite}>
            {sites
              .find((site) => id === site.id)
              .name.substring(0, NAME_LENGTH)}
            {sites.find((s) => id === s.id).name.length > NAME_LENGTH
              ? '...'
              : null}
          </p>
        </>
      ) : (
        <Loading />
      )}
    </MenuItem>
  ),
  timeoutStore,
  timeoutMapper
);
IdToName.displayName = 'IdToName';

function SiteSelector({
  sites,
  currentSite,
  loading,
  includeAllSites = false,
  isDashboard = false,
  className = null,
}) {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  useEffect(() => {
    fetchSites(!includeAllSites);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  useEffect(() => {
    let i;
    if (currentSite > 0) {
      i = setInterval(() => {
        getTimeoutStatus(currentSite);
      }, 3000);
    }
    return () => {
      if (i) clearInterval(i);
    };
  }, [currentSite]);

  if (loading) return <Loading />;
  let _sites = [...sites];

  if (includeAllSites && !_sites.find((x) => x.id === 0)) {
    _sites = [{ id: 0, name: t('Alla') }, ...sites];
  }

  function handleChange(e) {
    const siteId = e.target.value;
    if (siteId === 0) {
      if (_sites?.[0]?.id === 0) {
        siteStore.currentSite = 0;
      }
    } else {
      getSiteInfo(siteId);
      getTimeoutStatus(siteId);
    }
  }

  const filtered = _sites.filter((s) =>
    s.name.toLowerCase().includes(query?.toLowerCase())
  );

  const arrowIcon = ({ ...props }) => (
    <ArrowDropDown
      {...props}
      className={classNames(props.className, styles.dropdownIcon)}
    />
  );

  return (
    <Select
      id="current-site-selector"
      className={classNames(styles.container, {
        [styles.header]: !isDashboard,
        [className]: className,
      })}
      value={currentSite}
      onChange={handleChange}
      renderValue={(id) => <IdToName id={id} sites={_sites} />}
      onClose={() => setQuery('')}
      variant="outlined"
      margin="dense"
      MenuProps={{ variant: 'menu' }}
      IconComponent={arrowIcon}
    >
      <div value={currentSite || ''}>
        <Header
          val={query}
          setVal={setQuery}
          selectedSite={_sites.find((s) => s.id === currentSite)}
        />
      </div>
      {filtered.length === 0 ? (
        <p className={styles.emptyList}>
          {t('Inga anläggningar matchade söktermen')}
        </p>
      ) : (
        filtered.map((site) => (
          <MenuItem value={site.id} key={site.id} className={styles.item}>
            <Business className={styles.icon} />
            <p className={styles.row}>{site.name}</p>
          </MenuItem>
        ))
      )}
    </Select>
  );
}

const siteMapper = (state) => ({
  currentSite: state.currentSite,
  sites: state.sites,
  loading: state.isFetching,
});

export default withState(SiteSelector, siteStore, siteMapper);
