import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff, MailOutline } from '@material-ui/icons';
import { SaveButton } from './Buttons';
import TextInput from './TextInput';
import LanguageSwitcher from './LanguageSwitcher';
import { login } from '../actions/UserActions';
import tfaStore from '../stores/TfaStore';
import userStore from '../stores/UserStore';
import { withStates } from '../utils/with-state';
import styles from './Login.module.css';

import logoSv from '../images/wings.png';
import logoEn from '../images/wings-en.png';
import { ReactComponent as EyeIllustration } from '../images/safe_eye_dark.svg';

function Login({ usesTfa, token, verified }) {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [firstPart, setFirstPart] = useState(true);
  const [tfaCode, setTfaCode] = useState('');

  function doLogin(e) {
    e.preventDefault();
    login(email, password, tfaCode.replace(/\D/g, ''))
      .then(({ usesTfa: _usesTfa }) => {
        if (_usesTfa && !verified) setFirstPart(!firstPart);
      })
      .catch((err) => {
        let errObj = err?.response?.data?.error;
        setError(errObj);
      });
  }

  if (token) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={i18n.language === 'sv' ? logoSv : logoEn}
          alt="Wings logo"
          className={styles.logo}
        />
        <h1>{t('Logga in på Portalen')}</h1>
        {error && <p className={styles.error}>{error.details}</p>}
        <form onSubmit={doLogin} className={styles.form}>
          {firstPart && (
            <>
              <div className={styles.formElement}>
                <p>{t('E-mail')}</p>
                <TextInput
                  placeholder="name@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    className: styles.formField,
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutline />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={styles.formElement}>
                <p>{t('Lösenord')}</p>
                <TextInput
                  placeholder={'**************'}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    className: styles.formField,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={t('Visa lösenord')}
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          size="small"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </>
          )}
          {!firstPart && usesTfa && !verified && !token && (
            <div className={styles.tfaBox}>
              <p>{t('Skriv in tvåfaktorskoden från din app')}</p>
              <TextInput
                label={t('Verifieringskod')}
                value={tfaCode}
                onChange={(e) => setTfaCode(e.target.value)}
                InputProps={{
                  className: styles.formField,
                }}
              />
            </div>
          )}
          <div>
            <SaveButton
              type="submit"
              disabled={email === '' || password === ''}
            >
              {t('Logga in')}
            </SaveButton>
          </div>
        </form>
        <div className={styles.links}>
          <LanguageSwitcher />
        </div>
        <div className={styles.links}>
          <Link to="/forgot-password">{t('Glömt Lösenord?')}</Link>
          <Link to="/privacy-policy">{t('Integritetspolicy')}</Link>
        </div>
        <EyeIllustration
          className={styles.bigEye}
          title={t('Öga illustration')}
        />
      </div>
      <div className={styles.illustration}>
        <div className={styles.slogan}>
          <h1>{t('Din Digitala Väktare')}</h1>
          <h3>{t('Se, styr och organisera')}</h3>
        </div>
      </div>
    </div>
  );
}

const userMapper = (state) => ({ token: state.token });
const tfaMapper = (state) => ({
  usesTfa: state.usesTfa,
  verified: state.verified,
});

export default withStates(
  Login,
  [userStore, tfaStore],
  [userMapper, tfaMapper]
);
