import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import styles from './Sidebar.module.css';

function Sidebar({
  children,
  className,
  title,
  text,
  Icon = null,
  backRoute = null,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.container, {
        [className]: !!className,
      })}
    >
      <div className={styles.header}>
        {backRoute && (
          <div className={styles.backButton}>
            <Link className={styles.link} to={backRoute}>
              <Button startIcon={<ArrowBackIcon />}>{t('Tillbaka')}</Button>
            </Link>
          </div>
        )}
        {Icon && <div className={styles.icon}>{Icon}</div>}
        <h2 className={styles.title}>{title}</h2>
      </div>
      {text && <div className={styles.text}>{text}</div>}
      {children}
    </div>
  );
}

export default Sidebar;
