import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Divider, Checkbox } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { SaveButton } from '../../Buttons';
import TextInput from '../../TextInput';
import TimeIntervalPicker from '../../TimeIntervalPicker';
import hStyles from './Holiday.module.css';
import fStyles from '../../configuration/Form.module.css';

const styles = { ...fStyles, ...hStyles };
const THEME = createTheme({
  palette: {
    secondary: {
      main: '#e30613',
    },
  },
});

function Holiday({ holiday, onSubmit, clearError }) {
  const { t } = useTranslation();
  const [name, setName] = useState(holiday?.name || '');
  const [start, setStart] = useState(
    holiday?.start ? moment(holiday.start) : moment().startOf('day')
  );
  const [end, setEnd] = useState(
    holiday?.end ? moment(holiday.end) : moment().endOf('day')
  );
  const [isRecurring, setIsRecurring] = useState(holiday?.isRecurring || false);
  const [err, setErr] = useState({
    name: {
      err: false,
      text: t('Måste vara mellan 1 och 255 tecken'),
    },
    interval: {
      err: false,
      text: t('Start och slut får inte vara lika'),
    },
  });

  function checkError() {
    for (let e of Object.keys(err)) {
      let eVal;
      switch (e) {
        case 'name':
          eVal = name.length > 255 || name.length < 1;
          break;
        case 'interval':
          eVal = start.isSame(end);
          break;
        default:
          break;
      }
      if (eVal !== null) {
        let newErr = { ...err };
        newErr[e].err = eVal;
        setErr(newErr);
      }
    }
    return Object.values(err).some((x) => x.err);
  }
  function handleChange(id, val) {
    switch (id) {
      case 'name':
        setName(val);
        setErr({ ...err, name: { ...err.name, err: false } });
        break;
      case 'start':
        setStart(val);
        setErr({ ...err, interval: { ...err.interval, err: false } });
        break;
      case 'end':
        setEnd(val);
        setErr({ ...err, interval: { ...err.interval, err: false } });
        break;
      default:
        break;
    }
    clearError();
  }
  function onSave(e) {
    e.preventDefault();
    if (!checkError()) {
      onSubmit({
        name,
        start: start.valueOf(),
        end: end.valueOf(),
        isRecurring,
      });
    }
  }

  return (
    <div className={styles.container}>
      <form className={styles.flexColumn} onSubmit={onSave}>
        <div className={styles.section}>
          <TextInput
            label={t('Namn')}
            value={name}
            onChange={(e) => handleChange('name', e.target.value)}
            error={err.name.err}
            helperText={err.name.err ? err.name.text : null}
          />
        </div>

        <Divider light />

        <div className={styles.section}>
          <TimeIntervalPicker
            start={start}
            setStart={(d) => handleChange('start', d)}
            end={end}
            setEnd={(d) => handleChange('end', d)}
            error={err.interval.err}
            helperText={err.interval.err ? err.interval.text : null}
          />
        </div>

        <Divider light />

        <div className={styles.repeatCheck}>
          <p>{t('Ska denna röda dagen ske årligen?')}</p>
          <ThemeProvider theme={THEME}>
            <Checkbox
              checked={isRecurring}
              onChange={() => setIsRecurring(!isRecurring)}
            />
          </ThemeProvider>
        </div>

        <div>
          <div className={styles.footer}>
            <SaveButton type="submit">{t('Spara helgdag')}</SaveButton>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Holiday;
