import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Sidebar from '../Sidebar';
import TextInput from '../TextInput';
import { AddButton, UndoButton } from '../Buttons';
import { addSiteAction } from '../../actions/SiteActions';
import aStyles from './Add.module.css';
import cStyles from '../configuration/AddForm.module.css';

const styles = { ...cStyles, ...aStyles };

function AddSite() {
  const { t } = useTranslation();

  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [info, setInfo] = useState('');
  const [address, setAddress] = useState('');
  const [retainDays, setRetainDays] = useState(30);
  const [err, setErr] = useState({
    name: {
      err: false,
      text: t('Måste vara mellan 1 och 255 tecken'),
    },
    info: {
      err: false,
      text: t('Får inte vara tomt eller längre än 255 tecken'),
    },
    address: {
      err: false,
      text: t('Får inte vara tomt'),
    },
  });

  function checkError() {
    let newErr = { ...err };
    for (let e of Object.keys(err)) {
      let eVal;
      switch (e) {
        case 'name':
          eVal = name.length < 1 || name.length > 255;
          break;
        case 'info':
          eVal = info.length < 1 || info.length > 255;
          break;
        case 'address':
          eVal = info.length < 1;
          break;
        default:
          break;
      }
      if (eVal !== null) newErr[e].err = eVal;
    }
    setErr(newErr);
    return Object.values(err).some((x) => x.err);
  }
  function handleChange(id, val) {
    switch (id) {
      case 'name':
        setName(val);
        break;
      case 'info':
        setInfo(val);
        break;
      case 'address':
        setAddress(val);
        break;
      default:
        break;
    }
    setErr({
      ...err,
      [id]: {
        ...err[id],
        err: false,
      },
    });
  }
  function doAddSite(event) {
    event.preventDefault();
    if (!checkError())
      addSiteAction(name, info, address, retainDays)
        .then(() => setRedirect(true))
        .catch((e) => setError(e));
  }

  if (redirect) return <Redirect to="/admin/sites" />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title={t('Lägg till Anläggning')}
          text={t('Skapa en ny anläggning')}
          backRoute="/admin/sites"
        />
        {error && <p className={styles.error}>{error.message}</p>}
        <div className={styles.content}>
          <div className={styles.header}>
            <h2>{t('Ny anläggning')}</h2>
            <UndoButton href="/admin/sites">{t('Ångra & stäng')}</UndoButton>
          </div>
          <div className={styles.formContainer}>
            <form className={styles.form} onSubmit={doAddSite}>
              <TextInput
                label={t('Anläggningens namn')}
                value={name}
                onChange={(e) => handleChange('name', e.target.value)}
                error={err.name.err}
                helperText={err.name.err ? err.name.text : null}
              />
              <TextInput
                label={t('Address')}
                value={address}
                onChange={(e) => handleChange('address', e.target.value)}
                error={err.address.err}
                helperText={
                  err.address.err
                    ? err.address.text
                    : t(
                        'Adressen kommer användas av väktaren, så se till att den är korrekt'
                      )
                }
              />
              <TextInput
                label={t('Information')}
                value={info}
                onChange={(e) => handleChange('info', e.target.value)}
                multiline
                rows={4}
                rowsMax={7}
                error={err.info.err}
                helperText={err.info.err ? err.info.text : null}
              />
              <div>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  className={styles.siteRetainDaysSelect}
                >
                  <InputLabel htmlFor="retain-days">
                    {t('Dagar att spara video')}
                  </InputLabel>
                  <Select
                    label={t('Dagar att spara video')}
                    labelId="retain-days"
                    type="number"
                    value={retainDays}
                    onChange={(e) => setRetainDays(e.target.value)}
                    renderValue={(v) => t('{{count}} dagar', { count: v })}
                    MenuProps={{ variant: 'menu' }}
                  >
                    {[3, 14, 30].map((x, idx) => (
                      <MenuItem key={idx} value={x}>
                        {t('{{count}} dagar', { count: x })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.footer}>
                <AddButton type="submit">{t('Lägg till anläggning')}</AddButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSite;
