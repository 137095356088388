import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, IconButton } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import ConfirmDialog from '../../configuration/ConfirmDialog';
import aStyles from '../../configuration/List.module.css';
import moStyles from '../../mouseOver.module.css';

import {
  getDocumentData,
  deleteDocument,
  getDocuments,
} from '../../../actions/DocumentActions';
import documentStore from '../../../stores/DocumentStore';
import Table from '../../Table';

const styles = { ...aStyles, ...moStyles };

function DocumentList({ documents, sites }) {
  const { t } = useTranslation();

  async function onDownload({ name, id, fileType, siteId }) {
    let link = document.createElement('a');
    await getDocumentData(id, siteId);
    let doc = documentStore.docData;
    if (!doc) return;
    const fileEnding = fileType.split('/')[1];
    link.href = URL.createObjectURL(doc);
    link.download = `${name}.${fileEnding}`;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }

  const [toDelete, setToDelete] = useState(null);
  async function onDeleteDocument(doc) {
    await deleteDocument(doc.id, doc.siteId);
    setToDelete(null);
    getDocuments();
  }

  const data = documents.map((doc) => ({
    [t('Titel')]: (
      <Button
        onClick={() => onDownload(doc)}
        variant="contained"
        color="primary"
        value={doc.name}
      >
        {' '}
        {doc.name}{' '}
      </Button>
    ),
    [t('Anläggning')]: sites.find((s) => s.id === doc.siteId)?.name || '',
    [t('Storlek')]: `${Math.floor(doc.size / 1000)} kB`,
    [t('Typ')]: doc.fileType,
    ' ': (
      <div className={styles.mouseOver}>
        <Link
          className={styles.link}
          to={{ pathname: `/admin/documents/${doc.id}`, siteId: doc.siteId }}
        >
          <IconButton>
            <VisibilityIcon />
            <p className={styles.mouseOverText}>{t('Titta & redigera')}</p>
          </IconButton>
        </Link>
        <IconButton className={styles.link} onClick={() => setToDelete(doc)}>
          <DeleteOutline />
          <p className={styles.mouseOverText}>{t('Ta bort')}</p>
        </IconButton>
      </div>
    ),
  }));

  return (
    <div className={styles.container}>
      {(!data || data.length === 0) && (
        <p className={styles.noEntryText}>
          {t('Du har för närvarande inga inlagda dokument')}
        </p>
      )}
      {data.length > 0 && (
        <Table
          data={data}
          showSearch
          searchPlaceholder={t('Sök efter dokument')}
          rowClass={styles.row}
        />
      )}
      {toDelete && (
        <ConfirmDialog
          open={toDelete !== null}
          title={t('Bekräfta borttagning')}
          text={t("Är du säker på att du vill ta bort '{{name}}'?", {
            name: toDelete.name,
          })}
          onDeny={() => setToDelete(null)}
          onConfirm={() => onDeleteDocument(toDelete)}
        />
      )}
    </div>
  );
}

export default DocumentList;
