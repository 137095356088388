import React, { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SaveButton } from '../../Buttons';
import TextInput from '../../TextInput';
import hStyles from './AlarmTag.module.css';
import fStyles from '../../configuration/Form.module.css';

const styles = { ...fStyles, ...hStyles };

function AlarmTag({ alarmTag, onSubmit, hideArchived }) {
  const { t } = useTranslation();

  const [name, setName] = useState(alarmTag?.name || '');
  const [archived, setArchived] = useState(alarmTag?.archived || false);

  const [err, setErr] = useState({
    name: {
      err: false,
      text: t('Måste vara mellan 1 och 255 tecken'),
    },
  });

  function checkError() {
    for (let e of Object.keys(err)) {
      let eVal;
      switch (e) {
        case 'name':
          eVal = name.length > 255 || name.length < 1;
          break;
        default:
          break;
      }
      if (eVal !== null) {
        let newErr = { ...err };
        newErr[e].err = eVal;
        setErr(newErr);
      }
    }
    return Object.values(err).some((x) => x.err);
  }
  function handleChange(id, val) {
    switch (id) {
      case 'name':
        setName(val);
        setErr({ ...err, name: { ...err.name, err: false } });
        break;
      default:
        break;
    }
  }
  function onSave(e) {
    e.preventDefault();
    if (!checkError()) {
      onSubmit({
        name,
        archived,
      });
    }
  }

  return (
    <div className={styles.container}>
      <form className={styles.flexColumn} onSubmit={onSave}>
        <div className={styles.section}>
          <TextInput
            label={t('Namn')}
            value={name}
            onChange={(e) => handleChange('name', e.target.value)}
            error={err.name.err}
            helperText={err.name.err ? err.name.text : null}
          />
        </div>

        {hideArchived ? null : (
          <div className={styles.repeatCheck}>
            <p>{t('Arkiverad')}</p>
            <Checkbox
              checked={archived}
              onChange={() => setArchived(!archived)}
            />
          </div>
        )}

        <div>
          <div className={styles.footer}>
            <SaveButton type="submit">{t('Spara larmtagg')}</SaveButton>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AlarmTag;
