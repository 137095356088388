import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  ExpandLess as CloseListIcon,
  ExpandMore as OpenListIcon,
  Notifications as NotificationIcon,
} from '@material-ui/icons';
import { SaveButton, UndoButton } from '../Buttons';
import SearchBar from '../SearchBar';
import Sidebar from '../Sidebar';
import TextInput from '../TextInput';
import Loading from '../Loading';
import ConfirmDialog from '../configuration/ConfirmDialog';
import { sendPushNotification } from '../../actions/PushActions';
import permissionGroupStore from '../../stores/PermissionGroupStore';
import { getPermissionGroups } from '../../actions/PermissionGroupActions';
import { fetchUsers } from '../../actions/UserListActions';
import userListStore from '../../stores/UserListStore';
import { withStates } from '../../utils/with-state';
import styles from './WritePushNotification.module.css';

const MESSAGE_MAX_LEN = 1000;
const SUBJECT_MAX_LEN = 50;

const SelectList = ({ users, selected, setSelected, permissionGroups }) => {
  const { t } = useTranslation();

  const [q, setQ] = useState('');
  const [adminsOpen, setAdminsOpen] = useState(false);
  const [customersOpen, setCustomersOpen] = useState(false);
  const [employeesOpen, setEmployeesOpen] = useState(false);
  const [permissionsOpen, setPermissionsOpen] = useState(false);

  const admins = useMemo(
    () => (users || []).filter((u) => u?.role?.toLowerCase() === 'admin'),
    [users]
  );
  const customers = useMemo(
    () => (users || []).filter((u) => u?.role?.toLowerCase() === 'kund'),
    [users]
  );

  const employees = useMemo(
    () =>
      (users || []).filter(
        (u) => u?.role?.toLowerCase() === 'anställd' || u?.isEmployee
      ),
    [users]
  );
  const clearSelected = () => setSelected(new Set());

  function reduceList(shouldSelect, newList, tbr) {
    return shouldSelect
      ? tbr.reduce((acc, a) => {
          if (!acc.has(a)) acc.add(a);
          return acc;
        }, newList)
      : tbr.reduce((acc, a) => {
          if (acc.has(a)) acc.delete(a);
          return acc;
        }, newList);
  }

  function handlePreset(value, shouldSelect) {
    let newList = new Set(Array.from(selected));
    switch (value) {
      case 'admins':
        newList = reduceList(shouldSelect, newList, admins);
        break;
      case 'customers':
        newList = reduceList(shouldSelect, newList, customers);
        break;
      case 'employees':
        newList = reduceList(shouldSelect, newList, employees);
        break;
      case 'permissions':
        newList = reduceList(shouldSelect, newList, permissionGroups);
        break;
      default:
        break;
    }
    setSelected(newList);
  }

  function handleChange(user) {
    let newList = new Set([...selected]);
    const hasUser = newList.has(user);
    if (hasUser) {
      newList.delete(user);
    } else {
      newList.add(user);
    }
    setSelected(newList);
  }

  const usersAreEqual = (x) => {
    const qlc = (q || '').toLowerCase();
    return (
      (x.email || '').toLowerCase().includes(qlc) ||
      (x.firstName || '').toLowerCase().includes(qlc) ||
      (x.lastName || '').toLowerCase().includes(qlc)
    );
  };

  const filteredAdmins = (admins || []).filter(usersAreEqual);
  const filteredCustomers = (customers || []).filter(usersAreEqual);
  const filteredEmployees = (employees || []).filter(usersAreEqual);

  const userLabel = (x) => `${x.email} (${x.lastName}, ${x.firstName})`;
  const permissionLabel = (x) => `${x.name}`;

  const selectedHas = useCallback((u) => selected.has(u), [selected]);

  const adminCheck = admins.every(selectedHas);
  const adminInde = admins.some(selectedHas);
  const customersCheck = customers.every(selectedHas);
  const customersInde = customers.some(selectedHas);
  const employeesCheck = employees.every(selectedHas);
  const employeesInde = employees.some(selectedHas);

  const permissionsCheck = permissionGroups.every(selectedHas);
  const permissionsInde = permissionGroups.some(selectedHas);

  const toggleAdminsOpen = () => setAdminsOpen(!adminsOpen);
  const toggleCustomersOpen = () => setCustomersOpen(!customersOpen);
  const toggleEmployeesOpen = () => setEmployeesOpen(!employeesOpen);
  const togglePermissionsOpen = () => setPermissionsOpen(!permissionsOpen);

  return (
    <div className={styles.listSection}>
      <SearchBar
        className={styles.search}
        value={q}
        onInput={(e) => setQ(e.target.value)}
        placeholder={t('Sök')}
        list={users}
      />
      <Divider style={{ marginTop: 16 }} />
      <List>
        <ListItem
          key="all"
          className={styles.listEntry}
          button
          dense
          onClick={clearSelected}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selected.size === 0}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t('Alla')} />
        </ListItem>
        <Divider component="li" />
        <ListItem key="admin" className={styles.listEntry} button dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={adminCheck}
              indeterminate={!adminCheck && adminInde}
              onChange={() => handlePreset('admins', !adminCheck)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary="Admins" onClick={toggleAdminsOpen} />
          {adminsOpen ? (
            <CloseListIcon onClick={toggleAdminsOpen} />
          ) : (
            <OpenListIcon onClick={toggleAdminsOpen} />
          )}
        </ListItem>
        <Collapse in={q.length || adminsOpen}>
          <List className={styles.selectSublist}>
            {(filteredAdmins || [])
              .map((a) => (
                <ListItem
                  key={a.id}
                  className={styles.listEntry}
                  dense
                  onClick={() => handleChange(a)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selected.has(a)}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={userLabel(a)} />
                </ListItem>
              ))
              .reduce(
                (prev, curr) => [prev, <Divider component="li" />, curr],
                []
              )}
          </List>
        </Collapse>
        <Divider component="li" style={{ height: adminsOpen ? 3 : 1 }} />
        <ListItem key="customers" className={styles.listEntry} button dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={customersCheck}
              indeterminate={!customersCheck && customersInde}
              onChange={() => handlePreset('customers', !customersCheck)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary="Kunder" onClick={toggleCustomersOpen} />
          {customersOpen ? (
            <CloseListIcon onClick={toggleCustomersOpen} />
          ) : (
            <OpenListIcon onClick={toggleCustomersOpen} />
          )}
        </ListItem>
        <Collapse in={q.length || customersOpen}>
          <List className={styles.selectSublist}>
            {(filteredCustomers || [])
              .map((a) => (
                <>
                  <ListItem
                    key={a.id}
                    className={styles.listEntry}
                    dense
                    onClick={() => handleChange(a)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selected.has(a)}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={userLabel(a)} />
                  </ListItem>
                </>
              ))
              .reduce(
                (prev, curr) => [prev, <Divider component="li" />, curr],
                []
              )}
          </List>
        </Collapse>
        <Divider component="li" style={{ height: customersOpen ? 3 : 1 }} />
        <ListItem key="employees" className={styles.listEntry} button dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={employeesCheck}
              indeterminate={!employeesCheck && employeesInde}
              onChange={() => handlePreset('employees', !employeesCheck)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={t('Anställda')}
            onClick={toggleEmployeesOpen}
          />
          {employeesOpen ? (
            <CloseListIcon onClick={toggleEmployeesOpen} />
          ) : (
            <OpenListIcon onClick={toggleEmployeesOpen} />
          )}
        </ListItem>
        <Collapse in={q.length || employeesOpen}>
          <List className={styles.selectSublist}>
            {(filteredEmployees || [])
              .map((a) => (
                <>
                  <ListItem
                    key={a.id}
                    className={styles.listEntry}
                    dense
                    onClick={() => handleChange(a)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selected.has(a)}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={userLabel(a)} />
                  </ListItem>
                </>
              ))
              .reduce(
                (prev, curr) => [prev, <Divider component="li" />, curr],
                []
              )}
          </List>
        </Collapse>
        <Divider component="li" style={{ height: permissionsOpen ? 3 : 1 }} />
        <ListItem key="permissions" className={styles.listEntry} button dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={permissionsCheck}
              indeterminate={!permissionsCheck && permissionsInde}
              onChange={() => handlePreset('permissions', !permissionsCheck)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={t('Behörighetsgrupper')}
            onClick={togglePermissionsOpen}
          />
          {permissionsOpen ? (
            <CloseListIcon onClick={togglePermissionsOpen} />
          ) : (
            <OpenListIcon onClick={togglePermissionsOpen} />
          )}
        </ListItem>
        <Collapse in={q.length || permissionsOpen}>
          <List className={styles.selectSublist}>
            {(permissionGroups || [])
              .map((a) => (
                <>
                  <ListItem
                    key={a.id}
                    className={styles.listEntry}
                    dense
                    onClick={() => handleChange(a)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selected.has(a)}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={permissionLabel(a)} />
                  </ListItem>
                </>
              ))
              .reduce(
                (prev, curr) => [prev, <Divider component="li" />, curr],
                []
              )}
          </List>
        </Collapse>
        <Divider component="li" style={{ height: permissionsOpen ? 3 : 1 }} />
      </List>
    </div>
  );
};

const MessageSection = ({ subject, message, handleChange }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.messageSection}>
      <h2 className={styles.rightTitle}>{t('Ditt meddelande')}</h2>
      <Divider />
      <FormControl className={styles.subjectFormControl}>
        <TextInput
          label={t('Rubrik')}
          value={subject}
          onChange={(e) => handleChange('subject', e.target.value)}
        />
        <FormHelperText component="div" className={styles.subjectHelperText}>
          <>
            <h4>
              {t(
                'Tänk på att hela din rubrik kanske inte kommer kunna ses av användaren'
              )}
            </h4>
            <p>{`${subject.length}/${SUBJECT_MAX_LEN}`}</p>
          </>
        </FormHelperText>
      </FormControl>
      <FormControl className={styles.message}>
        <TextInput
          label={t('Meddelande')}
          placeholder={t('Skriv ditt meddelande här...')}
          value={message}
          onChange={(e) => handleChange('message', e.target.value)}
          multiline
          minRows={8}
          maxRows={8}
        />
        <FormHelperText component="div" className={styles.messageHelperText}>
          <>
            <p className={styles.markdownLink}>
              {t('Meddelandefältet stödjer Markdown-formatering.')}
              <a
                href="https://www.markdownguide.org/cheat-sheet/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Läs mer här.')}
              </a>
            </p>
            <p>{`${message.length}/${MESSAGE_MAX_LEN}`}</p>
          </>
        </FormHelperText>
      </FormControl>
    </div>
  );
};

function WritePushNotification({ users, permissionGroups }) {
  const { t } = useTranslation();

  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [err, setErr] = useState({
    subject: {
      err: false,
      text: t('Måste vara mellan 1 och {{max}} tecken', {
        max: SUBJECT_MAX_LEN,
      }),
    },
    message: {
      err: false,
      text: t('Måste vara mellan 1 och {{max}} tecken', {
        max: MESSAGE_MAX_LEN,
      }),
    },
  });
  const [toSend, setToSend] = useState(null);
  const clearToSend = () => setToSend(null);
  const history = useHistory();

  useEffect(() => {
    fetchUsers();
    getPermissionGroups();
  }, []);

  function goBack() {
    history.push('/admin/users');
  }

  function checkError() {
    let newErr = { ...err };
    for (let id of Object.keys(err)) {
      let eVal;
      switch (id) {
        case 'subject':
          eVal = subject.length < 1 || subject.length > SUBJECT_MAX_LEN;
          break;
        case 'message':
          eVal = subject.length < 1 || subject.length > MESSAGE_MAX_LEN;
          break;
        default:
          break;
      }
      if (eVal !== null) {
        newErr[id].err = eVal;
      }
    }
    setErr(newErr);
    return Object.values(err).some((x) => x.err);
  }
  function handleChange(id, val) {
    switch (id) {
      case 'subject':
        if (!subject.length <= SUBJECT_MAX_LEN) setSubject(val);
        else {
          setErr({ ...err, [id]: { ...err[id], err: true } });
          return;
        }
        break;
      case 'message':
        if (subject.length <= MESSAGE_MAX_LEN) setMessage(val);
        else {
          setErr({ ...err, [id]: { ...err[id], err: true } });
          return;
        }
        break;
      default:
        break;
    }
    setErr({ ...err, [id]: { ...err[id], err: false } });
  }

  function handlePendingSend(e) {
    e.preventDefault();
    if (checkError()) return;
    const userIds = [...selectedUsers].filter((u) => !u?.name).map((u) => u.id);
    const groups = [...selectedUsers].filter((u) => u?.name).map((u) => u.id);
    setToSend({ userIds, permissionGroups: groups, subject, message });
  }

  function onSubmit() {
    sendPushNotification(
      toSend.userIds,
      toSend.permissionGroups,
      toSend.subject,
      toSend.message
    ).then(() => history.push('/admin/users'));
  }

  function confirmationMessage() {
    const selUsers = [...selectedUsers].filter((u) => !u?.name);
    const groups = [...selectedUsers].filter((u) => u?.name);

    if (!groups.length) {
      return (
        <p>
          {t('Är du säker på att du vill skicka till {{count}} användare?', {
            count: selUsers.length,
          })}
        </p>
      );
    }
    return (
      <p>
        {t(
          'Är du säker på att du vill skicka till {{countPermissionGroups}} behörighetsgrupper och {{countUsers}} användare?',
          {
            countPermissionGroups: groups.length,
            countUsers: selUsers.length,
          }
        )}
      </p>
    );
  }

  if (!users || !permissionGroups) return <Loading center big />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title={t('Skicka Pushnotis')}
          text={t(
            'Skicka ett meddelande som användare får genom mail, portalen och ev. appen'
          )}
          Icon={<NotificationIcon fontSize="large" />}
        />
        <div className={styles.content}>
          <form onSubmit={handlePendingSend} className={styles.form}>
            <SelectList
              users={(users || []).filter((u) => u?.id !== 1)}
              selected={selectedUsers}
              setSelected={setSelectedUsers}
              permissionGroups={permissionGroups}
            />
            <div
              orientaion="vertical"
              component="p"
              flexItem
              style={{
                marginRight: 16,
                marginLeft: 16,
                backgroundColor: '#00000014',
                width: 1,
              }}
            />
            <div className={styles.right}>
              <MessageSection
                subject={subject}
                message={message}
                handleChange={handleChange}
              />
              <div className={styles.footer}>
                <UndoButton onClick={goBack}>Avbryt</UndoButton>
                <SaveButton
                  className={styles.sendBtn}
                  type="submit"
                  disabled={!(subject.length && message.length)}
                >
                  {t('Skicka meddelande')}
                </SaveButton>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ConfirmDialog
        open={toSend}
        title={t('Skicka meddelande')}
        text={confirmationMessage()}
        onDeny={clearToSend}
        onConfirm={onSubmit}
      />
    </div>
  );
}

const permissionGroupMapper = ({ permissionGroups }) => ({
  permissionGroups,
});

const userListMapper = (state) => ({
  users: state.users,
});

export default withStates(
  WritePushNotification,
  [permissionGroupStore, userListStore],
  [permissionGroupMapper, userListMapper]
);
