import i18n from './i18n';

const { t } = i18n;

export const ALARM_DESCRIPTIONS = {
  motion: t('Rörelse'),
  analysis: t('Rörelse (analys)'),
  analysis_error: t('Analysfel'),
  internal_error: t('Internt fel'),
  tampering: t('Kameramanipulering'),
  camera_alarm: t('Kameralarm'),
  lost_camera_connection: t('Anslutningsfel'),
  other: t('Övrigt'),
  high_latency: t('Långsam anslutning'),
  no_video: t('Ingen video'),
};

export const GROUP_MAP = {
  instructions: t('Instruktioner'),
  'live stream': t('Liveströmmar'),
  'authorized people': t('Behöriga personer'),
  exceptions: t('Undantag'),
  schedules: t('Scheman'),
  'point of contacts': t('Kontaktpersoner'),
  'timeout codes': t('Temporära koder'),
  alarms: t('Larm'),
  recordings: t('Inspelningar'),
  'motion detections': t('Rörelsedetektioner'),
  dashboard: t('Översikt'),
  'audit logs': t('Loggar'),
  'timeout logs': t('Avlarmningsloggar'),
  users: t('Användare'),
  documents: t('Dokument'),
};

export const VERB_MAP = {
  create: t('Skapa'),
  read: t('Läs'),
  update: t('Uppdatera'),
  delete: t('Ta bort'),
  view: t('Visa'),
  control: t('Kontrollera'),
  approve: t('Markera som läst'),
  osm: t('OSM'),
};
