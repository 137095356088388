import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Sidebar from '../../Sidebar';
import Document from './Document';
import { UndoButton } from '../../Buttons';
import {
  getDocuments,
  addDocument as addDocumentAction,
} from '../../../actions/DocumentActions';
import siteStore from '../../../stores/SiteStore';

import styles from '../../configuration/AddForm.module.css';
import { fetchSites } from '../../../actions/SiteActions';
import { withStates } from '../../../utils/with-state';

function AddDocument({ sites }) {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(null);

  const clearError = () => setError(null);

  useEffect(() => {
    fetchSites(false);
  }, []);

  async function addDocument({ name, file, siteId }) {
    addDocumentAction({
      name,
      file,
      siteId,
    })
      .then(() => {
        getDocuments();
        setRedirect(true);
      })
      .catch((e) => setError(e));
  }

  if (redirect) return <Redirect to="/admin/documents" />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title={t('Ladda upp nytt dokument')}
          text={t('Ladda upp ett nytt dokument på en anläggning')}
          backRoute="/admin/documents"
        />
        <div className={styles.content}>
          <div className={styles.header}>
            <h2>{t('Dokumentuppladdning')}</h2>
            <UndoButton href="/admin/documents">
              {t('Ångra & stäng')}
            </UndoButton>
          </div>
          <div className={styles.form}>
            {error && (
              <p className={styles.error}>
                {error?.response?.data?.error?.details}
              </p>
            )}
            <Document
              exception={{}}
              sites={sites}
              onSubmit={addDocument}
              clearError={clearError}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStates(
  AddDocument,
  [siteStore],
  [({ sites }) => ({ sites })]
);
