import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import PermissionGroup from './PermissionGroup';
import Sidebar from '../../Sidebar';
import { UndoButton } from '../../Buttons';
import permissionGroupStore from '../../../stores/PermissionGroupStore';
import withState from '../../../utils/with-state';
import {
  getPermissionGroups,
  getPermissions,
  addPermissionGroup as addPermissionGroupAction,
} from '../../../actions/PermissionGroupActions';
import styles from '../../configuration/AddForm.module.css';

function AddPermissionGroup({ allPermissions }) {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getPermissions();
  }, []);

  const clearError = () => setError(null);

  async function addPermissionGroup({ name, permissions, isAdminExclusive }) {
    addPermissionGroupAction({
      name,
      permissions,
      isAdminExclusive,
    })
      .then(() => {
        getPermissionGroups();
        setRedirect(true);
      })
      .catch((e) => setError(e));
  }

  if (redirect) return <Redirect to="/admin/permission-groups" />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title={t('Skapa ny behörighetsgrupp')}
          text={t('Skapa en ny behörighetsgrupp för Safe och deras kunder')}
          backRoute="/admin/permission-groups"
        />
        <div className={styles.content}>
          <div className={styles.header}>
            <h2>{t('Skapa behörighetsgrupp')}</h2>
            <UndoButton href="/admin/permission-groups">
              {t('Ångra & stäng')}
            </UndoButton>
          </div>
          <div className={styles.form}>
            {error && (
              <p className={styles.error}>
                {error?.response?.data?.error?.details?.[0]?.message ||
                  t('Något gick fel')}
              </p>
            )}
            <PermissionGroup
              permissions={allPermissions}
              exception={{}}
              onSubmit={addPermissionGroup}
              clearError={clearError}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withState(
  AddPermissionGroup,
  permissionGroupStore,
  ({ permissions }) => ({
    allPermissions: permissions,
  })
);
