import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog,
} from '@material-ui/core';

import { dismissTemporaryLink } from '../actions/RecordingsActions';
import recordingsStore from '../stores/RecordingsStore';
import withState from '../utils/with-state';

function TemporaryLinkDialog({ temporaryLink }) {
  const { t } = useTranslation();

  const linkRef = useCallback((node) => {
    if (node) {
      const r = document.createRange();
      r.selectNodeContents(node);
      const s = window.getSelection();
      s.removeAllRanges();
      s.addRange(r);
    }
  }, []);

  return (
    <Dialog open={!!temporaryLink} onClose={dismissTemporaryLink}>
      <DialogTitle>{t('Temporär länk')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>
            {t(
              'En temporär länk för din exporterade video har genererats. Länken går att använda i 7 dygn och videon kommer därefter raderas automatiskt.'
            )}
          </p>
          <br />
          <p>
            <a
              ref={linkRef}
              href={temporaryLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {temporaryLink}
            </a>
          </p>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default withState(
  TemporaryLinkDialog,
  recordingsStore,
  ({ temporaryLink }) => ({ temporaryLink })
);
