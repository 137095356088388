import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { requestNewPass } from '../actions/UserActions';
import TextInput from './TextInput';
import { SaveButton } from './Buttons';
import styles from './ForgotPass.module.css';
import LanguageSwitcher from './LanguageSwitcher';

import logoSv from '../images/wings.png';
import logoEn from '../images/wings-en.png';
import { ReactComponent as EyeIllustration } from '../images/safe_eye_dark.svg';

function ForgotPass() {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const history = useHistory();

  function goBack() {
    history.push('/');
  }

  function sendPassRequest(e) {
    e.preventDefault();
    requestNewPass({ email })
      .then(() => {
        goBack();
      })
      .catch((err) => {
        setError(err);
      });
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={i18n.language === 'sv' ? logoSv : logoEn}
          alt="Wings logo"
          className={styles.logo}
          onClick={goBack}
        />
        <h1>{t('Glömt ditt lösenord?')}</h1>
        {error && <p>{error.message}</p>}
        <h3 className={styles.subtitle}>
          {t(`Skriv in din e-postadress som du angav vid registrering, så skickar vi
          en återställningslänk.`)}
        </h3>
        <form onSubmit={sendPassRequest} className={styles.form}>
          <h2 className={styles.emailTitle}>E-postadress</h2>
          <TextInput
            placeholder="name@email.com"
            className={styles.emailInput}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className={styles.buttonContainer}>
            <SaveButton type="submit" disabled={!email.length}>
              {t('Återställ lösenord')}
            </SaveButton>
          </div>
          <div className={styles.languageSwitcher}>
            <LanguageSwitcher />
          </div>
        </form>
        <EyeIllustration
          className={styles.bigEye}
          title={t('Öga illustration')}
        />
      </div>
      <div className={styles.illustration} />
    </div>
  );
}
export default ForgotPass;
