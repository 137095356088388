import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import SubHeader from '../SubHeader';
import userStore from '../../stores/UserStore';
import withState from '../../utils/with-state';

function AdminHeader({ role, ...props }) {
  const { t } = useTranslation();

  const adminLinks = [
    {
      to: '/admin/sites',
      title: t('Anläggningar'),
    },
    {
      to: '/admin/users',
      title: t('Användare'),
    },
    {
      to: '/admin/holidays',
      title: t('Röda dagar'),
    },
    {
      to: '/admin/permission-groups',
      title: t('Behörighetsgrupper'),
    },
    {
      to: '/admin/vms',
      title: t('VMS'),
    },
    {
      to: '/admin/alarm-tags',
      title: t('Larmtaggar'),
    },
    {
      to: '/admin/documents',
      title: t('Dokument'),
    },
  ];

  const customerLinks = [
    {
      to: '/admin/users',
      title: t('Användare'),
    },
  ];

  return (
    <>
      <Header activeTab="admin" showSiteSelector={false} />
      <SubHeader
        links={role === 'admin' ? adminLinks : customerLinks}
        {...props}
      />
    </>
  );
}

export default withState(AdminHeader, userStore, ({ role }) => ({ role }));
