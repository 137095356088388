import React, { useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { svSE, enUS } from '@material-ui/core/locale';
import ClockIcon from '@material-ui/icons/QueryBuilder';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { createSnack } from '../actions/SnackActions';

const locale = 'sv';
moment.updateLocale(locale);
export default function TimeIntervalPicker({
  start,
  setStart,
  end,
  setEnd,
  ...props
}) {
  const { t, i18n } = useTranslation();

  const timeProps = {
    format: 'HH:mm',
    ampm: false,
    disableToolbar: true,
    keyboardIcon: <ClockIcon />,
    invalidDateMessage: t('Felformaterad tid'),
    ...props,
  };

  const updateStart = useCallback(
    (val) => {
      if (val > end) {
        return createSnack(
          t(
            'Det går inte att välja en starttid efter som är senare än sluttiden.'
          )
        );
      }

      setStart(val);
    },
    [setStart, end, t]
  );

  const updateEnd = useCallback(
    (val) => {
      if (val <= start) {
        return createSnack(
          t(
            'Det går inte att välja en sluttid efter som är tidigare än starttiden.'
          )
        );
      }

      setEnd(val);
    },
    [setEnd, start, t]
  );

  function handleChange({ d, s, e }) {
    if (d && d.isValid()) {
      let time = d
        .clone()
        .startOf('day')
        .diff(start.clone().startOf('day'), 'days');
      let newStart = start.clone().add(time, 'days');
      let newEnd = end.clone().add(time, 'days');
      setStart(newStart);
      setEnd(newEnd);
    }
    if (s && s.isValid()) {
      updateStart(
        start
          .clone()
          .startOf('day')
          .add(s.hours(), 'hours')
          .add(s.minutes(), 'minutes')
      );
    }
    if (e && e.isValid()) {
      updateEnd(
        start
          .clone()
          .startOf('day')
          .add(e.hours(), 'hours')
          .add(e.minutes(), 'minutes')
      );
    }
  }

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#e30613',
        },
      },
      typography: {
        fontFamily: 'MaisonNeue',
      },
    },
    i18n.language === 'sv' ? svSE : enUS
  );

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={locale}
      >
        <Grid container direction="column">
          <KeyboardDatePicker
            label={t('Datum')}
            format="yyyy-MM-DD"
            value={start}
            onChange={(d) => handleChange({ d })}
            disableToolbar={true}
            minDateMessage={t('Datumet får inte vara mindre än 1900-01-01')}
            maxDateMessage={t('Datumet får inte vara större än 2100-01-01')}
            invalidDateMessage={t('Felformaterat datum')}
          />
          <KeyboardTimePicker
            label={t('Start')}
            value={start}
            onChange={(s) => handleChange({ s })}
            {...timeProps}
          />
          <KeyboardTimePicker
            label={t('Slut')}
            value={end}
            onChange={(e) => handleChange({ e })}
            {...timeProps}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}
