import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Holiday from './Holiday';
import Sidebar from '../../Sidebar';
import { UndoButton } from '../../Buttons';
import {
  getHolidays,
  addHoliday as addHolidayAction,
} from '../../../actions/HolidayActions';
import styles from '../../configuration/AddForm.module.css';

function AddHoliday() {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(null);

  const clearError = () => setError(null);
  async function addHoliday({
    name,
    start,
    end,
    relations,
    setAlarm,
    isRecurring,
  }) {
    addHolidayAction({
      relations,
      name,
      start,
      end,
      setAlarm,
      isRecurring,
    })
      .then(() => {
        getHolidays();
        setRedirect(true);
      })
      .catch((e) => setError(e));
  }

  if (redirect) return <Redirect to="/admin/holidays" />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title={t('Skapa ny helgdag')}
          text={t('Skapa en ny helgdag för Safe och deras kunder')}
          backRoute="/admin/holidays"
        />
        <div className={styles.content}>
          <div className={styles.header}>
            <h2>{t('Helgdagsbyggare')}</h2>
            <UndoButton href="/admin/holidays">{t('Ångra & stäng')}</UndoButton>
          </div>
          <div className={styles.form}>
            {error && (
              <p className={styles.error}>
                {error?.response?.data?.error?.details}
              </p>
            )}
            <Holiday
              exception={{}}
              onSubmit={addHoliday}
              clearError={clearError}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddHoliday;
