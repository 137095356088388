import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { SaveButton } from './Buttons';
import TextInput from './TextInput';
import { setNewPassword } from '../actions/UserActions';
import styles from './ForgotPass.module.css';

import logoSv from '../images/wings.png';
import logoEn from '../images/wings-en.png';
import { ReactComponent as EyeIllustration } from '../images/safe_eye_dark.svg';

function SetNewPass({ ...props }) {
  const { t, i18n } = useTranslation();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [error, setError] = useState(null);
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [showNewPass, setShowNewPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const passUrl = props?.match?.params?.passUrl;

  function onPassChange() {
    if (newPass && confirmPass && newPass === confirmPass) {
      setNewPassword({ passUrl, newPass }).catch(setError);
      setShouldRedirect(true);
    } else if (!newPass || !confirmPass) {
      setError(
        new Error(
          t(
            'Du behöver fylla i båda fälten innan du kan skicka det nya lösenordet'
          )
        )
      );
    } else if (newPass !== confirmPass) {
      setError(
        new Error(t('Det nya lösenordet och bekräftelsen måste vara lika'))
      );
    }
  }

  if (shouldRedirect) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={i18n.language === 'sv' ? logoSv : logoEn}
          alt="Wings logo"
          className={styles.logo}
        />
        <h1>{t('Nytt lösenord')}</h1>
        {error && <p>{error.message}</p>}
        <h3 className={styles.subtitle}>
          {t('Välj ett lösenord som ska ersätta ditt gamla.')}
        </h3>
        <form onSubmit={onPassChange} className={styles.form}>
          <TextInput
            className={styles.emailInput}
            label={t('Nytt lösenord')}
            type={showNewPass ? 'text' : 'password'}
            value={newPass}
            onChange={(e) => setNewPass(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('Visa lösenord')}
                    onClick={() => setShowNewPass(!showNewPass)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showNewPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            className={styles.emailInput}
            label={t('Bekräfta lösenord')}
            type={showPassConfirm ? 'text' : 'password'}
            value={confirmPass}
            onChange={(e) => setConfirmPass(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('Visa lösenord')}
                    onClick={() => setShowPassConfirm(!showPassConfirm)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassConfirm ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className={styles.buttonContainer}>
            <SaveButton type="submit">{t('Ändra lösenord')}</SaveButton>
          </div>
        </form>
        <EyeIllustration
          className={styles.bigEye}
          alt={t('Öga illustration')}
        />
      </div>
      <div className={styles.illustration}>
        <div className={styles.slogan}>
          <h1>{t('Din Digitala Väktare')}</h1>
          <h3>{t('Se, styr och organisera')}</h3>
        </div>
      </div>
    </div>
  );
}

export default SetNewPass;
