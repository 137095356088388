import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AlarmTag from './AlarmTag';
import Sidebar from '../../Sidebar';
import { UndoButton } from '../../Buttons';
import {
  getAlarmTags,
  createAlarmTag,
} from '../../../actions/AlarmTagsActions';
import styles from '../../configuration/AddForm.module.css';

function AddAlarmTag() {
  const { t } = useTranslation();

  const [redirect, setRedirect] = useState(false);

  async function addAlarmTag({ name, color }) {
    createAlarmTag({
      name,
      color,
    }).then(() => {
      getAlarmTags();
      setRedirect(true);
    });
  }

  if (redirect) return <Redirect to="/admin/alarm-tags" />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title={t('Skapa ny larmtagg')}
          text={t('Skapa en ny larmtagg')}
          backRoute="/admin/alarm-tags"
        />
        <div className={styles.content}>
          <div className={styles.header}>
            <h2>{t('Larmtaggar')}</h2>
            <UndoButton href="/admin/alarm-tags">
              {t('Ångra & stäng')}
            </UndoButton>
          </div>
          <div className={styles.form}>
            <AlarmTag
              hideArchived={true}
              alarmTag={{}}
              onSubmit={addAlarmTag}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAlarmTag;
