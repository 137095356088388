import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const LANGUAGES = {
  sv: { nativeName: 'Svenska' },
  en: { nativeName: 'English' },
};

export default function LanguageSwitcher() {
  const { t, i18n } = useTranslation();

  return (
    <FormControl>
      <InputLabel>{t('Språk')}</InputLabel>
      <Select
        value={i18n.language}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        label={t('Välj språk')}
      >
        {Object.entries(LANGUAGES).map(([key, { nativeName }]) => (
          <MenuItem key={key} value={key}>
            {nativeName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
