import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@material-ui/icons/Description';
import DocumentList from './DocumentList';
import Sidebar from '../../Sidebar';
import Loading from '../../Loading';
import { SaveButton, AddButton } from '../../Buttons';
import { getDocuments, clearDocuments } from '../../../actions/DocumentActions';
import documentStore from '../../../stores/DocumentStore';
import { withStates } from '../../../utils/with-state';
import styles from '../../configuration/Configuration.module.css';
import siteStore from '../../../stores/SiteStore';
import { fetchSites } from '../../../actions/SiteActions';

function EmptyContainer() {
  const { t } = useTranslation();

  return (
    <>
      <Sidebar
        title={t('Se över dina dokument här')}
        text={t('Lägg till dokument här')}
        Icon={<DescriptionIcon fontSize="large" />}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <h3>{t('Alla dokument')}</h3>
        </div>
        <div className={styles.empty}>
          <h2 className={styles.emptyTitle}>
            {t('Du har för närvarande inga inlagda dokument')}
          </h2>
          <div className={styles.iconCircle}>
            <DescriptionIcon className={styles.emptyIcon} />
          </div>
          <div>
            <SaveButton href="/admin/documents/new">
              {t('Skapa dokument')}
            </SaveButton>
          </div>
        </div>
      </div>
    </>
  );
}

function DocumentConfiguration({ documents, sites }) {
  const { t } = useTranslation();

  useEffect(() => {
    clearDocuments();
    getDocuments();
    fetchSites(false);
  }, []);

  if (!sites) return <Loading big center />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        {documents?.length === 0 && <EmptyContainer />}

        {documents?.length > 0 && (
          <>
            <Sidebar
              title={t('Organisera och skapa dokument')}
              text=""
              Icon={<DescriptionIcon fontSize="large" />}
            />
            <div className={styles.content}>
              <div className={styles.header}>
                <h3>{t('Alla dokument')}</h3>
                <AddButton href="/admin/documents/new">
                  {t('Skapa ny')}
                </AddButton>
              </div>
              <div className={styles.paper}>
                <DocumentList documents={documents || []} sites={sites} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function siteMapper({ sites }) {
  return { sites };
}

export default withStates(
  DocumentConfiguration,
  [documentStore, siteStore],
  [
    (state) => ({
      documents: state.documents,
    }),
    siteMapper,
  ]
);
