import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BuildingIcon from '@material-ui/icons/Business';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import { IconButton } from '@material-ui/core';
import { AddButton } from '../Buttons';
import Sidebar from '../Sidebar';
import Table from '../Table';
import Loading from '../Loading';

import { fetchSites } from '../../actions/SiteActions';
import { trimStr } from '../../utils/generation';
import siteStore from '../../stores/SiteStore';
import withState from '../../utils/with-state';
import aStyles from './AdminList.module.css';
import moStyles from '../mouseOver.module.css';

const styles = { ...aStyles, ...moStyles };

function SiteList({ sites, loading }) {
  const { t } = useTranslation();

  useEffect(() => {
    fetchSites(false);
  }, []);

  const data = (sites || []).map((site) => ({
    [t('Anläggning')]: trimStr(site.name),
    [t('Antal Användare')]: `${site?.userCount || 0}`,
    [t('Antal Kameror')]: `${site?.cameraCount || 0}`,
    ' ': (
      <div className={styles.mouseOver}>
        <Link to={`/admin/sites/${site.id}`} style={{ textDecoration: 'none' }}>
          <IconButton>
            <VisibilityIcon />
            <p className={styles.mouseOverText}>{t('Titta & redigera')}</p>
          </IconButton>
        </Link>
      </div>
    ),
  }));

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title={t('Anläggningar')}
          text={t('Se över Safe Solutions AB anläggningar')}
          Icon={<BuildingIcon fontSize="large" />}
        />
        {loading ? (
          <Loading big center />
        ) : (
          <div className={styles.content}>
            <div className={styles.header}>
              <h2>{t('Alla anläggningar')}</h2>
              <AddButton href="/admin/sites/new">
                {t('Ny Anläggning')}
              </AddButton>
            </div>
            <div className={styles.listContainer}>
              <Table
                data={data}
                showSearch
                searchPlaceholder={t('Sök efter anläggning')}
                rowClass={styles.row}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withState(SiteList, siteStore, (state) => ({
  sites: state.sites,
  loading: state.isFetching,
}));
