import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './PrivacyPolicy.module.css';

import logoSv from '../images/wings.png';
import logoEn from '../images/wings-en.png';
import { ReactComponent as EyeIllustration } from '../images/safe_eye_dark.svg';

function PrivacyPolicy() {
  const { i18n } = useTranslation();

  const history = useHistory();

  function goBack() {
    history.push('/');
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={i18n.language === 'sv' ? logoSv : logoEn}
          alt="Wings logo"
          className={styles.logo}
          onClick={goBack}
        />
        <h1>Integritetspolicy</h1>
        <p>
          Safe Solutions AB skulle vilja att du lämnar vissa uppgifter om dig
          själv till vår verksamhet. Dina personuppgifter behandlas och sparas
          av oss för att vi ska kunna fullgöra vårt åtagande, ta kontakt eller
          ge den information som efterfrågas. De personuppgifter som kan komma
          att behandlas är främst sådan som du direkt eller indirekt lämnar till
          oss när du tar en kontakt med oss.
        </p>
        <p>
          De uppgifter vi avser att samla in och behandla är e-postadress,
          telefonnummer och ibland även namn och adress. Du avgör själv om du
          vill lämna några uppgifter till oss. Uppgifterna kommer endast att
          behandlas av oss, och i de fall vi är beroende av annan part för att
          genomföra leverans kommer de att kunna behandla dessa. Vi avser att
          behandla uppgifterna så länge du är kund eller önskar att ta del av
          vår information.
        </p>
        <p>
          Du har enligt 26 § personuppgiftslagen (1998:204) rätt att gratis, en
          gång per kalenderår, efter skriftligt undertecknad ansökan ställd till
          oss, få besked om vilka personuppgifter om dig som vi behandlar och
          hur vi behandlar dessa. Du har också rätt att enligt 28 §
          personuppgiftslagen begära rättelse i fråga om personuppgifter som vi
          behandlar om dig. Du har också alltid rätt att skriftligen återkalla
          lämnade samtycken. Anser du att dina personuppgifter har behandlats
          felaktig har du möjlighet att lämna klagomål till tillsynsmyndigheten
          (Integrationsskyddsmyndigheten - IMY).
        </p>
        <EyeIllustration className={styles.bigEye} title="Öga illustration" />
      </div>
      <div className={styles.illustration} />
    </div>
  );
}
export default PrivacyPolicy;
