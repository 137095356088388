import React from 'react';
import { useTranslation } from 'react-i18next';
import VerifyTfa from './VerifyTfa';
import { deleteTfa } from '../../actions/TfaActions';

function DeleteTfa() {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t('Ta bort Tvåfaktorsautentisering')}</h1>
      <div>
        <p>
          {t(
            'Bekräfta att du vill ta bort din tvåfaktorsautentisering genom att mata in koden i din app'
          )}
        </p>
        <VerifyTfa onVerify={deleteTfa} />
      </div>
    </>
  );
}

export default DeleteTfa;
