import React, { lazy, useEffect, Suspense } from 'react';
import { decode } from 'jsonwebtoken';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useInterval } from 'react-use';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Header from './components/Header';
import LoggedInWrapper from './components/LoggedInWrapper';
import Login from './components/Login';
import Logout from './components/Logout';
import ForgotPass from './components/ForgotPass';
import SetNewPass from './components/SetNewPass';
import PrivacyPolicy from './components/PrivacyPolicy';
import Admin from './views/Admin';
import Settings from './views/Settings';
import SnackBar from './components/SnackBar';
import TemporaryLinkDialog from './components/TemporaryLinkDialog';
import { renewToken, getUser } from './actions/UserActions';
import userStore from './stores/UserStore';
import withState from './utils/with-state';

const Tv = lazy(() => import('./views/Tv'));
const Overview = lazy(() => import('./views/Overview'));
const Configuration = lazy(() => import('./views/Configuration'));
const Cameras = lazy(() => import('./components/cameras/Cameras'));
const NotFound = lazy(() => import('./views/NotFound'));

const queryClient = new QueryClient();

function App({ token, userInfo }) {
  const { i18n } = useTranslation();

  useInterval(() => {
    renewToken();
  }, 1000 * 60 * 60);

  useEffect(() => {
    if (token && !userInfo) {
      getUser(0);
    }
  }, [token, userInfo]);

  useEffect(() => {
    if (
      token &&
      userInfo?.id &&
      decode(token).id === userInfo.id &&
      i18n.language !== userInfo.locale
    ) {
      i18n.changeLanguage(userInfo.locale);
    }
  }, [token, userInfo, i18n]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <SnackBar />
        <TemporaryLinkDialog />
        <Suspense fallback={<Header />}>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/forgot-password">
              <ForgotPass />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/reset-password/:passUrl" component={SetNewPass} />
            <Route path="/logout">
              <LoggedInWrapper Component={Logout} />
            </Route>
            <Route path="/tv">
              <LoggedInWrapper Component={Tv} />
            </Route>
            <Route path="/Overview">
              <LoggedInWrapper Component={Overview} />
            </Route>
            <Route path="/configuration">
              <LoggedInWrapper Component={Configuration} />
            </Route>
            <Route path="/admin">
              <LoggedInWrapper Component={Admin} />
            </Route>
            <Route
              exact
              path="/cameras/:id"
              render={(nav) => <LoggedInWrapper Component={Cameras} {...nav} />}
            />
            <Route path="/cameras">
              <LoggedInWrapper Component={Cameras} />
            </Route>
            <Route path="/settings">
              <LoggedInWrapper Component={Settings} />
            </Route>
            <LoggedInWrapper
              Component={Redirect}
              exact
              from="/"
              to="/overview"
            />

            <NotFound />
          </Switch>
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}

export default withState(App, userStore, (state) => ({
  token: state.token,
  userInfo: state.userInfo,
}));
