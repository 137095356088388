import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Delete } from '@material-ui/icons';
import { SaveButton } from '../Buttons';
import Loading from '../Loading';
import PhoneField from '../PhoneField';
import MultiSelect from '../MultiSelect';
import TextInput from '../TextInput';
import ConfirmDialog from '../configuration/ConfirmDialog';
import { getPermissionGroups } from '../../actions/PermissionGroupActions';
import permissionGroupStore from '../../stores/PermissionGroupStore';
import siteStore from '../../stores/SiteStore';
import userStore from '../../stores/UserStore';
import { withStates } from '../../utils/with-state';
import PermissionGroupDialog from './PermissionGroupDialog';
import { useHasPermissions, PERMISSIONS } from '../../utils/permissions';
import {
  clearUser,
  deleteUser as deleteUserAction,
} from '../../actions/UserActions';
import styles from './EditUser.module.css';

const roles = {
  Kund: 'customer',
  Admin: 'admin',
  Anställd: 'employee',
};

function EditUser({
  user,
  sites,
  permissionGroups,
  onSave,
  currentSite,
  userRole,
}) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [phoneNbr, setPhoneNbr] = useState('');
  const [role, setRole] = useState(roles[user?.role]);
  const [permissionGroupId, setPermissionGroupId] = useState(0);
  const [selectedSites, setSites] = useState([]);
  const [toDelete, setToDelete] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const canEdit = useHasPermissions(PERMISSIONS.USERS_UPDATE);
  const canDelete = useHasPermissions(PERMISSIONS.USERS_DELETE);

  useEffect(() => {
    getPermissionGroups();

    return () => clearUser();
  }, []);

  useEffect(() => {
    if (user && sites) {
      setFname(user.firstName);
      setLname(user.lastName);
      setPhoneNbr(user?.phoneNbr);
      setRole(roles[user?.role]);
      setPermissionGroupId(user?.permissionGroup?.id || 0);
      setSites(
        (user?.sites || []).reduce(
          (acc, x) => [...acc, sites.find((s) => s.id === x)],
          []
        )
      );
    }
  }, [user, sites]);

  function onSubmit(e) {
    e.preventDefault();
    onSave({
      firstName: fname,
      lastName: lname,
      phoneNbr,
      role,
      permissionGroupId,
      selectedSites: currentSite ? null : selectedSites,
      ignoreSites: !!currentSite,
    });
  }

  function deleteUser() {
    if (currentSite) {
      deleteUserAction(user.id, { siteId: currentSite }).then(() => {
        setRedirect(true);
      });
    } else {
      deleteUserAction(user.id).then(() => {
        setRedirect(true);
      });
    }
  }

  if (!user || !permissionGroups) return <Loading center big />;

  if (redirect) return <Redirect to="/admin/users" />;

  let allPermissionGroups = structuredClone(permissionGroups);

  const adminRestrictedGroupEdit =
    !permissionGroups.find((x) => x.id === user?.permissionGroup?.id) &&
    !!user?.permissionGroup;

  if (adminRestrictedGroupEdit && user.permissionGroup) {
    allPermissionGroups.push(user.permissionGroup);
  }

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <div className={styles.row}>
        <TextInput
          className={styles.entry}
          label={t('Förnamn')}
          value={fname}
          onChange={(e) => setFname(e.target.value)}
        />
        <TextInput
          className={styles.entry}
          label={t('Efternamn')}
          value={lname}
          onChange={(e) => setLname(e.target.value)}
        />
      </div>
      <TextInput
        className={styles.entry}
        label={t('Email')}
        value={user?.email}
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        disabled
      />
      <div className={styles.row}>
        {
          <>
            <FormControl
              variant="outlined"
              className={styles.select}
              margin="dense"
            >
              <InputLabel>Roll</InputLabel>
              <Select
                label={t('Roll')}
                value={role}
                onChange={(e) => setRole(e.target.value)}
                margin="dense"
                disabled={userRole !== 'admin'}
              >
                <MenuItem value="customer">{t('Kund')}</MenuItem>
                <MenuItem value="admin">{t('Admin')}</MenuItem>
                <MenuItem value="employee">{t('Anställd')}</MenuItem>
              </Select>
            </FormControl>
          </>
        }
        {
          <>
            <FormControl
              variant="outlined"
              className={styles.entry}
              margin="dense"
            >
              <InputLabel>{t('Behörighetsgrupp')}</InputLabel>
              <Select
                label={t('Behörighetsgrupp')}
                value={user?.permissionGroup?.id || permissionGroupId || ''}
                onChange={(e) => setPermissionGroupId(e.target.value)}
                margin="dense"
                disabled={adminRestrictedGroupEdit}
              >
                {allPermissionGroups.map((permissionGroup) => (
                  <MenuItem value={permissionGroup.id}>
                    {permissionGroup.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDialogOpen(true);
              }}
              disabled={permissionGroupId === null}
              className={styles.infoButton}
              size="small"
            >
              <InfoIcon />
            </IconButton>
            <PermissionGroupDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              permissionGroup={user?.permissionGroup}
            />
          </>
        }
      </div>
      <div>
        <PhoneField
          label={t('Telefonnummer')}
          value={phoneNbr}
          onChange={(e) => setPhoneNbr(e)}
        />
      </div>
      {!currentSite && (
        <MultiSelect
          className={styles.entry}
          label={t('Länkade anläggningar')}
          list={sites}
          selectedValues={selectedSites}
          selectValues={setSites}
          renderValue={(s) =>
            t('{{ count }} anläggningar', { count: s.length })
          }
          searchable
          disabled={(user?.role || '').toLowerCase() === 'admin'}
        />
      )}
      <div className={styles.footer}>
        {canDelete &&
          (currentSite ? (
            <SaveButton
              admin
              onClick={() => setToDelete(true)}
              startIcon={<Delete />}
              disabled={user?.id === 1}
            >
              {t('Koppla bort användare')}
            </SaveButton>
          ) : (
            <SaveButton
              admin
              onClick={() => setToDelete(true)}
              startIcon={<Delete />}
              disabled={user?.id === 1}
            >
              {t('Radera användare')}
            </SaveButton>
          ))}
        {canEdit && (
          <SaveButton type="submit">{t('Spara ändringar')}</SaveButton>
        )}
      </div>
      <ConfirmDialog
        open={toDelete}
        title={currentSite ? t('Koppla bort användare') : t('Radera användare')}
        text={
          <>
            <p>
              {t('Är du säker på att du vill {{ action }} {{ name }}?', {
                action: currentSite ? t('koppla bort') : t('radera'),
                name: `${fname} ${lname}`,
              })}
            </p>
            <p>
              {currentSite ? (
                <span>
                  {t(
                    'Användaren kommer inte längre ha tillgång till anläggningen.'
                  )}
                </span>
              ) : (
                <span>
                  {t(
                    'Detta kommer att ta bort ALL data relaterad till användaren.'
                  )}
                </span>
              )}
            </p>
          </>
        }
        onDeny={() => setToDelete(false)}
        onConfirm={deleteUser}
      />
    </form>
  );
}

const permissionGroupMapper = ({ permissionGroups }) => ({
  permissionGroups,
});

const userMapper = ({ role }) => ({
  userRole: role,
});

const siteMapper = ({ currentSite }) => ({
  currentSite,
});

export default withStates(
  EditUser,
  [permissionGroupStore, userStore, siteStore],
  [permissionGroupMapper, userMapper, siteMapper]
);
