import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Table from '../../Table';
import { SaveButton } from '../../Buttons';
import TextInput from '../../TextInput';
import hStyles from './Document.module.css';
import fStyles from '../../configuration/Form.module.css';

const styles = { ...fStyles, ...hStyles };

const Searchbar = React.memo(({ val, setVal }) => (
  <TextField
    className={styles.searchbar}
    value={val}
    onKeyDown={(e) => e.stopPropagation()}
    onChange={(e) => setVal(e.target.value)}
    InputProps={{ startAdornment: <Search /> }}
    autoFocus
    autoComplete="off"
    placeholder="Sök Anläggning"
  />
));

function Document({ doc, sites, onSubmit, clearError }) {
  const { t } = useTranslation();
  const [name, setName] = useState(doc?.name || '');
  const [site, setSite] = useState(doc?.siteId || 0);
  const [query, setQuery] = useState('');
  const [err, setErr] = useState({
    name: {
      err: false,
      text: t('Måste vara mellan 1 och 255 tecken'),
    },
    site: {
      err: false,
      text: t('Måste välja anläggning'),
    },
    file: {
      err: false,
      text: 'Måste välja fil',
    },
  });

  useEffect(() => {
    setName(doc?.name || '');
    setSite(doc?.siteId || 0);
  }, [doc]);

  function checkError() {
    for (let e of Object.keys(err)) {
      let eVal;
      switch (e) {
        case 'name':
          eVal = name.length > 255 || name.length < 1;
          break;
        case 'site':
          eVal = !site;
          break;
        case 'file':
          eVal = !document.getElementById('file')?.files.length;
          break;
        default:
          break;
      }
      if (eVal !== null) {
        let newErr = { ...err };
        newErr[e].err = eVal;
        setErr(newErr);
      }
    }
    return Object.values(err).some((x) => x.err);
  }
  function handleChange(id, val) {
    switch (id) {
      case 'name':
        setName(val);
        setErr({ ...err, name: { ...err.name, err: false } });
        break;
      case 'site':
        setSite(val);
        setErr({ ...err, site: { ...err.site, err: false } });
        break;
      case 'file':
        setErr({ ...err, file: { ...err.file, err: false } });
        break;
      default:
        break;
    }
    clearError();
  }
  function onSave(e) {
    e.preventDefault();
    if (!checkError() && typeof onSubmit === 'function') {
      const file = document.getElementById('file')?.files?.[0];

      onSubmit({
        name,
        file,
        siteId: site,
      });
    }
  }
  const data = doc?.readBy
    ? doc.readBy.map((d) => ({
        [t('Namn')]: `${d.firstName} ${d.lastName}`,
        [t('Godkändes')]: new Date(d.readAt).toDateString(),
      }))
    : [];
  const manyUsers = data.length > 10;
  return (
    <div className={styles.container}>
      <form className={styles.flexColumn} onSubmit={onSave}>
        <div className={styles.section}>
          <TextInput
            label={t('Namn')}
            value={name}
            onChange={(e) => handleChange('name', e.target.value)}
            error={err.name.err}
            helperText={err.name.err ? err.name.text : null}
          />
        </div>

        <Divider light />
        <div className={styles.section}>
          <Input
            disableUnderline
            type="file"
            id="file"
            name="file"
            onChange={(e) => handleChange('file', e)}
            inputProps={{
              accept: 'image/png,image/jpeg,.pdf',
            }}
          />
          {err.file.err && (
            <FormHelperText error={err.file.err}>
              {err.file.text}
            </FormHelperText>
          )}
        </div>

        <div className={styles.selects}>
          <FormControl
            variant="outlined"
            className={styles.select}
            margin="dense"
            display="flex"
            error={err.site.err}
          >
            <InputLabel id="site">{t('Anläggning')}</InputLabel>
            <Select
              label={t('Anläggning')}
              id="site-select"
              margin="dense"
              value={site}
              error={err.site.err}
              onChange={(e) => handleChange('site', e.target.value)}
            >
              <Searchbar val={query} setVal={setQuery} />
              {(
                sites.filter((s) =>
                  s.name.toLowerCase().includes(query?.toLowerCase())
                ) || []
              ).map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
            {err.site.err && <FormHelperText>{err.site.text}</FormHelperText>}
          </FormControl>

          <Divider light />
        </div>
        {doc && data && (
          <Table
            data={data}
            noPagination={!manyUsers}
            hideSearch={!manyUsers}
            searchPlaceholder={t('Sök efter användare')}
            rowClass={styles.row}
          />
        )}

        <div>
          <div className={styles.footer}>
            <SaveButton type="submit">{t('Spara dokument')}</SaveButton>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Document;
